import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRoute } from '@atlassian/jira-router-routes-proforma-routes/src/routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ProformaIssueFormsDirectEmbed, {
	LazyProformaIssueFormsDirectEmbed,
} from './ui/issue-forms-direct/embed/index.tsx';

export const routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRouteEntry: Route =
	createEntry(routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRoute, () => ({
		group: ROUTE_GROUPS_SOFTWARE,
		layout: chromelessLayout,
		resources: [projectContextResource],
		component: ProformaIssueFormsDirectEmbed,
		forPaint: [LazyProformaIssueFormsDirectEmbed],
		perfMetricKey: 'issue-forms-direct-embed',
	}));
