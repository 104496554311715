import React, { useCallback, useMemo, type PropsWithChildren } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { LazySuspense, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useIntl } from '@atlassian/jira-intl';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useDebuggerStatus, useIsAiEnabled } from '../../controllers/jql-debugger-data/index.tsx';
import {
	ERROR_BOUNDARY_IDS,
	PACKAGE_NAME_JQL_DEBUGGER,
	TEAM_NAME,
} from '../../common/constants.tsx';
import messages from './messages.tsx';
import { useJqlDebuggerAdditionalContext } from './context.tsx';

const TriggerButtonWithLogic = lazyForPaint(
	() =>
		import(/* webpackChunkName: "jql-debugger-trigger-button" */ './trigger-button-content.tsx'),
);

export type JQLDebuggerProps = PropsWithChildren<{
	testId: string;
}>;

export function JQLDebuggerTriggerButtonContent(props: JQLDebuggerProps) {
	const { children, testId } = props;
	const { formatMessage } = useIntl();
	const { jqlMessages, onSearch } = useJqlDebuggerAdditionalContext();
	const [debuggerState] = useDebuggerStatus();

	const [isAiEnabled] = useIsAiEnabled();

	const errors = useMemo(() => {
		return jqlMessages
			?.filter((message) => message.type === 'error')
			.map((message) => message.message?.toString())
			.filter(Boolean);
	}, [jqlMessages]);

	if (!isAiEnabled || !onSearch || !errors?.length) {
		return children;
	}

	return (
		<Box xcss={errorComponentStyles}>
			{debuggerState === 'INIT' && children}
			{debuggerState === 'INIT' && (
				<LazySuspense fallback={<Button>{formatMessage(messages.fixErrorWithAiNonFinal)}</Button>}>
					<TriggerButtonWithLogic testId={testId} />
				</LazySuspense>
			)}
		</Box>
	);
}

export function JQLDebuggerTriggerButton(props: JQLDebuggerProps) {
	const ChildrenFallback = useCallback(() => props.children, [props.children]);

	return (
		<JSErrorBoundary
			packageName={PACKAGE_NAME_JQL_DEBUGGER}
			id={ERROR_BOUNDARY_IDS.TRIGGER_BUTTON}
			teamName={TEAM_NAME}
			fallback={ChildrenFallback}
		>
			<JQLDebuggerTriggerButtonContent {...props} />
		</JSErrorBoundary>
	);
}

const errorComponentStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	rowGap: 'space.025',
	marginBottom: 'space.050',
});
