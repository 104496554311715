import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SUPPORT_CENTER_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { agentStudioRoute } from '@atlassian/jira-router-routes-agent-studio-routes/src/agentStudioRoute.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyAgentStudioPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-support-center-settings-agent-studio", jiraSpaEntry: "support-center-settings-agent-studio" */ '@atlassian/jira-support-center-settings-agent-studio/src/index.tsx'
		),
	),
);

export const AgentStudioComponent = () => (
	<LazyPage Page={LazyAgentStudioPage} pageId="agent-studio-page" shouldShowSpinner={false} />
);

export const agentStudioRouteEntry = createEntry(agentStudioRoute, () => ({
	group: ROUTE_GROUPS_SUPPORT_CENTER_SETTINGS,
	component: AgentStudioComponent,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	forPaint: [LazyAtlassianNavigation, LazyAgentStudioPage],
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));
