import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRoute } from '@atlassian/jira-router-routes-proforma-routes/src/routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ProformaIssueFormsDirect, {
	LazyProformaIssueFormsDirect,
} from './ui/issue-forms-direct/index.tsx';

export const routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRouteEntry: Route =
	createEntry(routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRoute, () => ({
		group: ROUTE_GROUPS_SERVICEDESK,
		layout: serviceProjectLayout,
		resources: [projectContextResource],
		component: ProformaIssueFormsDirect,
		navigation: {
			menuId: MENU_ID.PROJECTS,
		},
		forPaint: [LazyProformaIssueFormsDirect],
		perfMetricKey: 'issue-forms-direct',
	}));
