import React from 'react';
import camelCase from 'lodash/camelCase';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import {
	ROUTE_NAMES_SOFTWARE_SECURITY,
	ROUTE_GROUPS_SOFTWARE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSoftwareProjectHorizontalNavResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/software-horizontal-navigation/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { securityPageResource } from '@atlassian/jira-router-resources-software-security/src/services/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwareSecurityRoute } from '@atlassian/jira-router-routes-software-security-routes/src/softwareSecurityRoute.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { isSecurityPageEnabled } from './common/isSecurityPageEnabled.tsx';
import { LazySecurity } from './common/LazySecurity.tsx';

export const SecurityNextGen = () => (
	<LazyPage Page={LazySecurity} pageId="securityNextGen" shouldShowSpinner />
);

export const softwareSecurityNextGenRouteEntry: Route = createEntry(softwareSecurityRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	component: componentWithCondition(isSecurityPageEnabled, SecurityNextGen, ErrorPagesNotFound),
	layout: softwareProjectLayoutNoOnboarding,
	ufoName: 'software-security-combined',
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		staticSoftwareProjectHorizontalNavResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		projectContextResource,
		securityPageResource,
	],
	forPaint: [LazyAtlassianNavigation, LazySecurity],
	meta: {
		reporting: {
			id: ROUTE_NAMES_SOFTWARE_SECURITY,
			packageName: camelCase(ROUTE_NAMES_SOFTWARE_SECURITY),
			teamName: 'fusion-isotopes',
		},
	},
}));
