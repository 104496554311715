import React, {
	useCallback,
	useEffect,
	createContext,
	useContext,
	type ReactNode,
	type ReactElement,
} from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useFlagsService } from '@atlassian/jira-flags';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';
import { LEARN_MORE_IPH_ARTICLE_ID } from '../../common/constants.tsx';
import { AsyncSpotlightCard } from '../../common/ui/spotlight/index.tsx';
import type { SpotlightCardProps } from '../../common/ui/spotlight/spotlight-card/index.tsx';
import SpotlightTarget, {
	type SpotlightTargetProps,
} from '../../common/ui/spotlight/spotlight-target/index.tsx';

import messages from './messages.tsx';

export const NinChangeboardingContext = createContext(false);

export const EnableNinChangeboardingProvider = ({ children }: { children: ReactNode }) => (
	<NinChangeboardingContext.Provider value>{children}</NinChangeboardingContext.Provider>
);

export const NinChangeboardingTourTarget = ({ children, engagementId }: SpotlightTargetProps) => {
	const isTourEnabled = useContext(NinChangeboardingContext);
	if (!isTourEnabled) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return children as ReactElement;
	}
	return <SpotlightTarget engagementId={engagementId}>{children}</SpotlightTarget>;
};

export const NinChangeboardingTourCard = ({
	mode,
	view,
	hasIssueResults,
	isAiEnabled,
}: SpotlightCardProps) => {
	const isTourEnabled = useContext(NinChangeboardingContext);

	if (!isTourEnabled) {
		return null;
	}
	return (
		<AsyncSpotlightCard
			mode={mode}
			view={view}
			hasIssueResults={hasIssueResults}
			isAiEnabled={isAiEnabled}
		/>
	);
};

export const useGlobalScopeOptInFlag = () => {
	const isFlagEnabled = useContext(NinChangeboardingContext);
	const { showFlag } = useFlagsService();
	const onOpenInProductHelpArticle = useHelpPanelLink();

	const onLearnMore = useCallback(() => {
		onOpenInProductHelpArticle(LEARN_MORE_IPH_ARTICLE_ID);
	}, [onOpenInProductHelpArticle]);

	useEffect(() => {
		const shouldShowOptInFlag = sessionStorage.getItem('showNinOptInFlag');
		if (isFlagEnabled && shouldShowOptInFlag === 'true') {
			showFlag({
				type: 'success',
				title: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.successOptInTitleIssueTermRefresh
					: messages.successOptInTitle,
				description: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.successOptInDescIssueTermRefresh
					: messages.successOptInDesc,
				actions: [
					{
						content: messages.successActionLearnMoreLink,
						onClick: onLearnMore,
					},
				],
				isAutoDismiss: false,
				testId: 'issue-navigator-changeboarding.optin-success-flag',
			});
			sessionStorage.removeItem('showNinOptInFlag');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
