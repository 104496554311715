import { globalSettingsContactAdministratorRouteEntry } from '@atlassian/jira-router-routes-admin-pages-contact-administrator-entries/src/globalSettingsContactAdministratorRouteEntry.tsx';
import { emailNotificationsLogAccessRouteEntry } from '@atlassian/jira-router-routes-admin-pages-email-notifications-log-entries/src/emailNotificationsLogAccessRouteEntry.tsx';
import { externalImportRouteEntry } from '@atlassian/jira-router-routes-admin-pages-external-import-entries/src/externalImportRouteEntry.tsx';
import { globalSettingsGlobalPermissionsRouteEntry } from '@atlassian/jira-router-routes-admin-pages-global-permissions-entries/src/globalSettingsGlobalPermissionsRouteEntry.tsx';
import { globalSettingsIssueHierarchyRedirectRouteEntry } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries/src/globalSettingsIssueHierarchyRedirectRouteEntry.tsx';
import { globalSettingsIssueHierarchyRouteEntry } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries/src/globalSettingsIssueHierarchyRouteEntry.tsx';
import { globalSettingsIssueTypesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries/src/globalSettingsIssueTypesRouteEntry.tsx';
import { globalSettingsIssueTypesSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries/src/globalSettingsIssueTypesSchemesRouteEntry.tsx';
import { productsJsmAuthenticationSettingsRouteEntry } from '@atlassian/jira-router-routes-admin-pages-jsm-authentication-settings-entries/src/productsJsmAuthenticationSettingsRouteEntry.tsx';
import { productsJsmCustomerAccessRouteEntry } from '@atlassian/jira-router-routes-admin-pages-jsm-customer-access-entries/src/productsJsmCustomerAccessRouteEntry.tsx';
import { globalSettingsIssuesNewIssueSearchRouteEntry } from '@atlassian/jira-router-routes-admin-pages-new-issue-features-entries/src/globalSettingsIssuesNewIssueSearchRouteEntry.tsx';
import { globalSettingsSystemBulkMailRouteEntry } from '@atlassian/jira-router-routes-admin-pages-outgoing-mail-entries/src/globalSettingsSystemBulkMailRouteEntry.tsx';
import { globalSettingsPerformanceCustomFieldRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceCustomFieldRouteEntry.tsx';
import { globalSettingsPerformanceIssueArchivalRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceIssueArchivalRouteEntry.tsx';
import { globalSettingsPerformanceMetricDetailRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceMetricDetailRouteEntry.tsx';
import { globalSettingsPerformanceOverviewRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceOverviewRouteEntry.tsx';
import { globalSettingsPerformanceProjectCleanupRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceProjectCleanupRouteEntry.tsx';
import { globalSettingsPerformanceProjectRoleActorRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceProjectRoleActorRouteEntry.tsx';
import { globalSettingsEditPermissionSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-permission-schemes-entries/src/globalSettingsEditPermissionSchemesRouteEntry.tsx';
import { globalSettingsPermissionSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-permission-schemes-entries/src/globalSettingsPermissionSchemesRouteEntry.tsx';
import { globalSettingsIssuesPrioritiesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-priorities-entries/src/globalSettingsIssuesPrioritiesRouteEntry.tsx';
import { globalSettingsIssuesPrioritySchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-priorities-entries/src/globalSettingsIssuesPrioritySchemesRouteEntry.tsx';
import { globalSettingsFormDataConnectionsRouteEntry } from '@atlassian/jira-router-routes-admin-pages-proforma-entries/src/globalSettingsFormDataConnectionsRouteEntry.tsx';
import { globalSettingsIssueFieldSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-screens-entries/src/globalSettingsIssueFieldSchemesRouteEntry.tsx';
import { globalSettingsIssueFieldScreensRouteEntry } from '@atlassian/jira-router-routes-admin-pages-screens-entries/src/globalSettingsIssueFieldScreensRouteEntry.tsx';
import { globalSettingsIssueTypesScreenSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-screens-entries/src/globalSettingsIssueTypesScreenSchemesRouteEntry.tsx';
import { routeNamesSystemConfigurationRedirectRouteEntry } from '@atlassian/jira-router-routes-admin-pages-system-configuration-entries/src/routeNamesSystemConfigurationRedirectRouteEntry.tsx';
import { systemConfigurationRouteEntry } from '@atlassian/jira-router-routes-admin-pages-system-configuration-entries/src/systemConfigurationRouteEntry.tsx';
import { globalSettingsSystemUiAnnouncementBannerRouteEntry } from '@atlassian/jira-router-routes-admin-pages-system-entries/src/globalSettingsSystemUiAnnouncementBannerRouteEntry.tsx';
import { globalSettingsIssueWorkflow1RouteEntry } from '@atlassian/jira-router-routes-admin-pages-workflows-entries/src/globalSettingsIssueWorkflow1RouteEntry.tsx';
import { globalSettingsIssueWorkflowEditRouteEntry } from '@atlassian/jira-router-routes-admin-pages-workflows-entries/src/globalSettingsIssueWorkflowEditRouteEntry.tsx';
import { arjCalendarEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjCalendarEmbedRouteEntry.tsx';
import { arjDependenciesEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjDependenciesEmbedRouteEntry.tsx';
import { arjIncrementEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjIncrementEmbedRouteEntry.tsx';
import { arjSummaryEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjSummaryEmbedRouteEntry.tsx';
import { arjTimelineEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjTimelineEmbedRouteEntry.tsx';
import { legacyPlanPageRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/legacyPlanPageRedirectRouteEntry.tsx';
import { legacyPlanReportPageRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/legacyPlanReportPageRedirectRouteEntry.tsx';
import { planExportPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planExportPageRouteEntry.tsx';
import { planPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planPageRouteEntry.tsx';
import { planPageWithoutScenarioIdRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planPageWithoutScenarioIdRouteEntry.tsx';
import { planReportPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planReportPageRouteEntry.tsx';
import { planSetupPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planSetupPageRouteEntry.tsx';
import { softwareAdvancedRoadmapsGettingStartedRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwareAdvancedRoadmapsGettingStartedRedirectRouteEntry.tsx';
import { softwarePlanCalendarRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanCalendarRouteEntry.tsx';
import { softwarePlanDependenciesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanDependenciesRouteEntry.tsx';
import { softwarePlanReleasesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanReleasesRouteEntry.tsx';
import { softwarePlanSettingsAutoSchedulerRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsAutoSchedulerRouteEntry.tsx';
import { softwarePlanSettingsCustomFieldsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsCustomFieldsRouteEntry.tsx';
import { softwarePlanSettingsExclusionRulesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsExclusionRulesRouteEntry.tsx';
import { softwarePlanSettingsFindYourIssueRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsFindYourIssueRouteEntry.tsx';
import { softwarePlanSettingsIssueSourcesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsIssueSourcesRouteEntry.tsx';
import { softwarePlanSettingsPermissionsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsPermissionsRouteEntry.tsx';
import { softwarePlanSettingsPlanInfoRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsPlanInfoRouteEntry.tsx';
import { softwarePlanSettingsProgramBoardsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsProgramBoardsRouteEntry.tsx';
import { softwarePlanSettingsRemovedIssuesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsRemovedIssuesRouteEntry.tsx';
import { softwarePlanSettingsSavedViewsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsSavedViewsRouteEntry.tsx';
import { softwarePlanSettingsScenariosRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsScenariosRouteEntry.tsx';
import { softwarePlanSettingsSchedulingRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsSchedulingRouteEntry.tsx';
import { softwarePlanSummaryRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSummaryRouteEntry.tsx';
import { softwarePlanTeamsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanTeamsRouteEntry.tsx';
import { softwarePlanTimelineRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanTimelineRouteEntry.tsx';
import { arjSettingsDependenciesLegacyRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsDependenciesLegacyRedirectRouteEntry.tsx';
import { arjSettingsDependenciesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsDependenciesRouteEntry.tsx';
import { arjSettingsLoadingLimitsLegacyRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsLoadingLimitsLegacyRedirectRouteEntry.tsx';
import { arjSettingsLoadingLimitsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsLoadingLimitsRouteEntry.tsx';
import { arjSettingsPermissionsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsPermissionsRouteEntry.tsx';
import { arjSettingsPermissionsRouteLegacyRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsPermissionsRouteLegacyRedirectRouteEntry.tsx';
import { archivedPlansRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/archivedPlansRouteEntry.tsx';
import { arjSettingsFinancialYearPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/arjSettingsFinancialYearPageRouteEntry.tsx';
import { planIncrementRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/planIncrementRouteEntry.tsx';
import { planIncrementsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/planIncrementsRouteEntry.tsx';
import { trashedPlanDirectLinkRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/trashedPlanDirectLinkRouteEntry.tsx';
import { trashPlansRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/trashPlansRouteEntry.tsx';
import { agentStudioRouteEntry } from '@atlassian/jira-router-routes-agent-studio-entries/src/index.tsx';
import { archivedProjectDirectLinkRouteEntry } from '@atlassian/jira-router-routes-archived-project-direct-link-entries/src/archivedProjectDirectLinkRouteEntry.tsx';
import { archivedProjectsDirectoryRouteEntry } from '@atlassian/jira-router-routes-archived-projects-directory-entries/src/archivedProjectsDirectoryRouteEntry.tsx';
import { archivedIssuesJsmRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesJsmRouteEntry.tsx';
import { archivedIssuesJswCompanyRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesJswCompanyRouteEntry.tsx';
import { archivedIssuesJswRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesJswRouteEntry.tsx';
import { archivedIssuesJwmRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesJwmRouteEntry.tsx';
import { archivedIssuesRedirectRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesRedirectRouteEntry.tsx';
import { browseProjectRedirectRouteEntry } from '@atlassian/jira-router-routes-browse-project-entries/src/browseProjectRedirectRouteEntry.tsx';
import { jiraBusinessBoardEmbedRouteEntry } from '@atlassian/jira-router-routes-business-board-entries/src/jiraBusinessBoardEmbedRouteEntry.tsx';
import { jiraBusinessBoardRouteEntry } from '@atlassian/jira-router-routes-business-board-entries/src/jiraBusinessBoardRouteEntry.tsx';
import { classicBusinessOverviewCalendarRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessOverviewCalendarRouteEntry.tsx';
import { classicBusinessOverviewSummaryRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessOverviewSummaryRouteEntry.tsx';
import { classicBusinessOverviewTimelineRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessOverviewTimelineRouteEntry.tsx';
import { classicBusinessProjectApprovalsRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectApprovalsRouteEntry.tsx';
import { classicBusinessProjectAttachmentsRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectAttachmentsRouteEntry.tsx';
import { classicBusinessProjectCalendarEmbedRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectCalendarEmbedRouteEntry.tsx';
import { classicBusinessProjectCalendarRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectCalendarRouteEntry.tsx';
import { classicBusinessProjectFormBuilderRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormBuilderRouteEntry.tsx';
import { classicBusinessProjectFormRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormRouteEntry.tsx';
import { classicBusinessProjectFormSubmitEmbedRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormSubmitEmbedRouteEntry.tsx';
import { classicBusinessProjectFormSubmitRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormSubmitRouteEntry.tsx';
import { classicBusinessProjectPagesRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectPagesRouteEntry.tsx';
import { classicBusinessProjectSampleOnboardingRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectSampleOnboardingRouteEntry.tsx';
import { classicBusinessProjectShortcutRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectShortcutRouteEntry.tsx';
import { jiraBusinessListEmbedRouteEntry } from '@atlassian/jira-router-routes-business-list-entries/src/jiraBusinessListEmbedRouteEntry.tsx';
import { jiraBusinessListRouteEntry } from '@atlassian/jira-router-routes-business-list-entries/src/jiraBusinessListRouteEntry.tsx';
import { jiraBusinessOverviewRedirectRouteEntry } from '@atlassian/jira-router-routes-business-redirect-entries/src/jiraBusinessOverviewRedirectRouteEntry.tsx';
import { jiraBusinessProjectRedirectRouteEntry } from '@atlassian/jira-router-routes-business-redirect-entries/src/jiraBusinessProjectRedirectRouteEntry.tsx';
import { jiraBusinessSummaryEmbedRouteEntry } from '@atlassian/jira-router-routes-business-summary-entries/src/jiraBusinessSummaryEmbedRouteEntry.tsx';
import { jiraBusinessSummaryRouteEntry } from '@atlassian/jira-router-routes-business-summary-entries/src/jiraBusinessSummaryRouteEntry.tsx';
import { jiraBusinessTimelineEmbedRouteEntry } from '@atlassian/jira-router-routes-business-timeline-entries/src/jiraBusinessTimelineEmbedRouteEntry.tsx';
import { jiraBusinessTimelineRouteEntry } from '@atlassian/jira-router-routes-business-timeline-entries/src/jiraBusinessTimelineRouteEntry.tsx';
import { classicProjectSettingsDataClassificationsRouteEntry } from '@atlassian/jira-router-routes-classic-project-settings-data-classifications-entries/src/classicProjectSettingsDataClassificationsRouteEntry.tsx';
import { classicProjectSettingsIssueLayoutRouteEntry } from '@atlassian/jira-router-routes-classic-project-settings-issue-layout-entries/src/classicProjectSettingsIssueLayoutRouteEntry.tsx';
import { compassProductSettingsRouteEntry } from '@atlassian/jira-router-routes-compass-product-settings-entries/src/compassProductSettingsRouteEntry.tsx';
import { confluenceProductSettingsRouteEntry } from '@atlassian/jira-router-routes-confluence-product-settings-entries/src/confluenceProductSettingsRouteEntry.tsx';
import { connectGeneralInvalidRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/connectGeneralInvalidRouteEntry.tsx';
import { connectGeneralRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/connectGeneralRouteEntry.tsx';
import { connectGeneralServiceDeskProjectRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/connectGeneralServiceDeskProjectRouteEntry.tsx';
import { connectProjectRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/connectProjectRouteEntry.tsx';
import { servicedeskConnectProjectRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/servicedeskConnectProjectRouteEntry.tsx';
import { createProjectRouteEntry } from '@atlassian/jira-router-routes-create-project-entries/src/createProjectRouteEntry.tsx';
import { dashboardEditRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardEditRouteEntry.tsx';
import { dashboardRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardRouteEntry.tsx';
import { dashboardViewEmbedRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardViewEmbedRouteEntry.tsx';
import { dashboardViewRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardViewRouteEntry.tsx';
import { dashboardWallboardRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardWallboardRouteEntry.tsx';
import { selectedDashboardRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/selectedDashboardRouteEntry.tsx';
import { systemDashboardEditRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/systemDashboardEditRouteEntry.tsx';
import { devopsConnectedProjectRouteEntry } from '@atlassian/jira-router-routes-devops-connected-project-entries/src/devopsConnectedProjectRouteEntry.tsx';
import { boardsDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/boardsDirectoryRedirectRouteEntry.tsx';
import { boardsDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/boardsDirectoryRouteEntry.tsx';
import { dashboardsDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/dashboardsDirectoryRedirectRouteEntry.tsx';
import { dashboardsDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/dashboardsDirectoryRouteEntry.tsx';
import { filtersDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/filtersDirectoryRedirectRouteEntry.tsx';
import { filtersDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/filtersDirectoryRouteEntry.tsx';
import { manageProjectsDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/manageProjectsDirectoryRouteEntry.tsx';
import { plansDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/plansDirectoryRouteEntry.tsx';
import { projectsDirectoryLegacyPathRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryLegacyPathRedirectRouteEntry.tsx';
import { projectsDirectoryManageRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryManageRedirectRouteEntry.tsx';
import { projectsDirectoryManageRootRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryManageRootRedirectRouteEntry.tsx';
import { projectsDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryRedirectRouteEntry.tsx';
import { projectsDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryRouteEntry.tsx';
import { secureFiltersDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/secureFiltersDirectoryRedirectRouteEntry.tsx';
import { eocAlertIssueSyncingCreateRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocAlertIssueSyncingCreateRouteEntry.tsx';
import { eocAlertIssueSyncingDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocAlertIssueSyncingDetailRouteEntry.tsx';
import { eocAlertIssueSyncingRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocAlertIssueSyncingRouteEntry.tsx';
import { eocTeamAlertIssueSyncingCreateRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocTeamAlertIssueSyncingCreateRouteEntry.tsx';
import { eocTeamAlertIssueSyncingDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocTeamAlertIssueSyncingDetailRouteEntry.tsx';
import { eocTeamAlertIssueSyncingRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocTeamAlertIssueSyncingRouteEntry.tsx';
import { eocApiKeyManagementRouteEntry } from '@atlassian/jira-router-routes-eoc-api-key-management-entries/src/eocApiKeyManagementRouteEntry.tsx';
import { eocAssignCustomRolesRouteEntry } from '@atlassian/jira-router-routes-eoc-assign-custom-roles-entries/src/eocAssignCustomRolesRouteEntry.tsx';
import { eocAutomationsRouteEntry } from '@atlassian/jira-router-routes-eoc-automations-entries/src/eocAutomationsRouteEntry.tsx';
import { eocCustomRolesRouteEntry } from '@atlassian/jira-router-routes-eoc-custom-roles-entries/src/eocCustomRolesRouteEntry.tsx';
import { eocGlobalAlertListRouteEntry } from '@atlassian/jira-router-routes-eoc-global-alerts-entries/src/eocGlobalAlertListRouteEntry.tsx';
import { eocGlobalAlertsDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-global-alerts-entries/src/eocGlobalAlertsDetailRouteEntry.tsx';
import { globalEscalationsRouteEntry } from '@atlassian/jira-router-routes-eoc-global-escalations-entries/src/index.tsx';
import { eocGlobalGroupDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-group-detail-entries/src/eocGlobalGroupDetailRouteEntry.tsx';
import { eocTeamGroupDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-group-detail-entries/src/eocTeamGroupDetailRouteEntry.tsx';
import { eocHeartbeatsRouteEntry } from '@atlassian/jira-router-routes-eoc-heartbeats-entries/src/eocHeartbeatsRouteEntry.tsx';
import { globalIncomingCallDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-incoming-call-entries/src/globalIncomingCallDetailRouteEntry.tsx';
import { globalIncomingCallRouteEntry } from '@atlassian/jira-router-routes-eoc-incoming-call-entries/src/globalIncomingCallRouteEntry.tsx';
import { globalIntegrationCreateRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationCreateRouteEntry.tsx';
import { globalIntegrationDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationDetailRouteEntry.tsx';
import { globalIntegrationListRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationListRouteEntry.tsx';
import { globalIntegrationLogRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationLogRouteEntry.tsx';
import { globalIntegrationsRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationsRouteEntry.tsx';
import { teamIntegrationCreateRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationCreateRouteEntry.tsx';
import { teamIntegrationDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationDetailRouteEntry.tsx';
import { teamIntegrationListRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationListRouteEntry.tsx';
import { teamIntegrationLogRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationLogRouteEntry.tsx';
import { teamIntegrationsRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationsRouteEntry.tsx';
import { eocGlobalMaintenanceRouteEntry } from '@atlassian/jira-router-routes-eoc-maintenance-entries/src/eocGlobalMaintenanceRouteEntry.tsx';
import { eocMaintenanceRouteEntry } from '@atlassian/jira-router-routes-eoc-maintenance-entries/src/eocMaintenanceRouteEntry.tsx';
import { eocOnCall1RouteEntry } from '@atlassian/jira-router-routes-eoc-on-call-entries/src/eocOnCall1RouteEntry.tsx';
import { eocOnCallRouteEntry } from '@atlassian/jira-router-routes-eoc-on-call-entries/src/eocOnCallRouteEntry.tsx';
import { eocOverviewAllTeamsRouteEntry } from '@atlassian/jira-router-routes-eoc-overview-entries/src/eocOverviewAllTeamsRouteEntry.tsx';
import { eocOverviewRouteEntry } from '@atlassian/jira-router-routes-eoc-overview-entries/src/eocOverviewRouteEntry.tsx';
import { eocPermissionsRouteEntry } from '@atlassian/jira-router-routes-eoc-permissions-entries/src/eocPermissionsRouteEntry.tsx';
import { alertPoliciesRouteEntry } from '@atlassian/jira-router-routes-eoc-policies-entries/src/alertPoliciesRouteEntry.tsx';
import { globalPoliciesRouteEntry } from '@atlassian/jira-router-routes-eoc-policies-entries/src/globalPoliciesRouteEntry.tsx';
import { eocGlobalReportsHomePageNav4RouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocGlobalReportsHomePageNav4RouteEntry.tsx';
import { eocGlobalReportsNav4RouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocGlobalReportsNav4RouteEntry.tsx';
import { eocReportsTeamRedirectRouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocReportsTeamRedirectRouteEntry.tsx';
import { eocReportsTeamRouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocReportsTeamRouteEntry.tsx';
import { eocReportsUnsubscribeRouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocReportsUnsubscribeRouteEntry.tsx';
import { roleBasedNotificationDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-role-based-notifications-entries/src/roleBasedNotificationDetailRouteEntry.tsx';
import { roleBasedNotificationsRouteEntry } from '@atlassian/jira-router-routes-eoc-role-based-notifications-entries/src/roleBasedNotificationsRouteEntry.tsx';
import { eocScheduleGlobalRouteEntry } from '@atlassian/jira-router-routes-eoc-schedule-global-entries/src/eocScheduleGlobalRouteEntry.tsx';
import { eocTeamAlertDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-team-alerts-entries/src/eocTeamAlertDetailRouteEntry.tsx';
import { eocTeamAlertListRouteEntry } from '@atlassian/jira-router-routes-eoc-team-alerts-entries/src/eocTeamAlertListRouteEntry.tsx';
import { eocTeamCustomerLogsDownloadRouteEntry } from '@atlassian/jira-router-routes-eoc-team-customer-logs-entries/src/eocTeamCustomerLogsDownloadRouteEntry.tsx';
import { eocTeamCustomerLogsRouteEntry } from '@atlassian/jira-router-routes-eoc-team-customer-logs-entries/src/eocTeamCustomerLogsRouteEntry.tsx';
import { unlockOperationsRouteEntry } from '@atlassian/jira-router-routes-eoc-unlock-operations-entries/src/unlockOperationsRouteEntry.tsx';
import { whoIsOnCallRouteEntry } from '@atlassian/jira-router-routes-eoc-who-is-on-call-entries/src/whoIsOnCallRouteEntry.tsx';
import { errorsNotFoundRouteEntry } from '@atlassian/jira-router-routes-errors-not-found-entries/src/errorsNotFoundRouteEntry.tsx';
import { forgeAdminRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeAdminRouteEntry.tsx';
import { forgeAdminSettingsRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeAdminSettingsRouteEntry.tsx';
import { forgeFieldContextualConfigurationRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeFieldContextualConfigurationRouteEntry.tsx';
import { forgeGlobalRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeGlobalRouteEntry.tsx';
import { forgePersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgePersonalSettingsRouteEntry.tsx';
import { forgeProjectRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeProjectRouteEntry.tsx';
import { forgeServicedeskQueueRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeServicedeskQueueRouteEntry.tsx';
import { globalMailSettingsRouteEntry } from '@atlassian/jira-router-routes-global-mail-settings-entries/src/globalMailSettingsRouteEntry.tsx';
import { globalSettingsCustomFieldsRouteEntry } from '@atlassian/jira-router-routes-global-settings-custom-fields-entries/src/globalSettingsCustomFieldsRouteEntry.tsx';
import { globalSettingsIssuesExplicitFieldAssociationRouteEntry } from '@atlassian/jira-router-routes-global-settings-issues-explicit-field-association-entries/src/globalSettingsIssuesExplicitFieldAssociationRouteEntry.tsx';
import { helpCenterSettingsCustomerAccessRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsCustomerAccessRouteEntry.tsx';
import { helpCenterSettingsCustomerNotificationsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsCustomerNotificationsRouteEntry.tsx';
import { helpCenterSettingsDetailsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsDetailsRouteEntry.tsx';
import { helpCenterSettingsEmailRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsEmailRouteEntry.tsx';
import { helpCenterSettingsFormEditRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsFormEditRouteEntry.tsx';
import { helpCenterSettingsFormsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsFormsRouteEntry.tsx';
import { helpCenterSettingsKbRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsKbRouteEntry.tsx';
import { helpCenterSettingsRedirectRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsRedirectRouteEntry.tsx';
import { helpCenterSettingsServiceHubsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsServiceHubsRouteEntry.tsx';
import { helpCenterSettingsTicketTypeFieldsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsTicketTypeFieldsRouteEntry.tsx';
import { helpCenterSettingsTicketTypesConfigRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsTicketTypesConfigRouteEntry.tsx';
import { homeOnboardingRedirectRouteEntry } from '@atlassian/jira-router-routes-home-onboarding-redirect-entries/src/homeOnboardingRedirectRouteEntry.tsx';
import { homeRedirectRouteEntry } from '@atlassian/jira-router-routes-home-redirect-entries/src/homeRedirectRouteEntry.tsx';
import { softwareIncidentsNextGenRouteEntry } from '@atlassian/jira-router-routes-incidents-entries/src/softwareIncidentsNextGenRouteEntry.tsx';
import { issueCreateEmbedRouteEntry } from '@atlassian/jira-router-routes-issue-create-embed-entries/src/issueCreateEmbedRouteEntry.tsx';
import { browseIssueEmbedRouteEntry } from '@atlassian/jira-router-routes-issue-entries/src/browseIssueEmbedRouteEntry.tsx';
import { browseIssueRouteEntry } from '@atlassian/jira-router-routes-issue-entries/src/browseIssueRouteEntry.tsx';
import { globalIssueNavigatorBrowseIssueRedirectRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/globalIssueNavigatorBrowseIssueRedirectRouteEntry.tsx';
import { issueNavigatorGlobalJiraRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorGlobalJiraRouteEntry.tsx';
import { issueNavigatorGlobalRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorGlobalRouteEntry.tsx';
import { issueNavigatorProjectBusinessRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectBusinessRouteEntry.tsx';
import { issueNavigatorProjectClassicRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectClassicRouteEntry.tsx';
import { issueNavigatorProjectServicedeskRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectServicedeskRouteEntry.tsx';
import { issueNavigatorProjectSoftwareRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectSoftwareRouteEntry.tsx';
import { projectIssueNavigatorRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/projectIssueNavigatorRouteEntry.tsx';
import { jiraHomeRouteEntry } from '@atlassian/jira-router-routes-jira-home-entries/src/jiraHomeRouteEntry.tsx';
import { jiraSearchRouteEntry } from '@atlassian/jira-router-routes-jira-search-entries/src/jiraSearchRouteEntry.tsx';
import { legacyRedirectRouteEntry } from '@atlassian/jira-router-routes-legacy-redirect-entries/src/legacyRedirectRouteEntry.tsx';
import { loginEmbedRouteEntry } from '@atlassian/jira-router-routes-login-embed-entries/src/loginEmbedRouteEntry.tsx';
import { appRequestsRouteEntry } from '@atlassian/jira-router-routes-marketplace-entries/src/appRequestsRouteEntry.tsx';
import { findAppsRouteEntry } from '@atlassian/jira-router-routes-marketplace-entries/src/findAppsRouteEntry.tsx';
import { promotionsRouteEntry } from '@atlassian/jira-router-routes-marketplace-entries/src/promotionsRouteEntry.tsx';
import { softwareCodeRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareCodeRouteEntry.tsx';
import { softwareNextgenRedirectRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareNextgenRedirectRouteEntry.tsx';
import { softwareReportsBurndownRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsBurndownRouteEntry.tsx';
import { softwareReportsBurnupRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsBurnupRouteEntry.tsx';
import { softwareReportsControlRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsControlRouteEntry.tsx';
import { softwareReportsCumulativeRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsCumulativeRouteEntry.tsx';
import { softwareReportsCycleTimeRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsCycleTimeRouteEntry.tsx';
import { softwareReportsDeploymentFrequencyRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsDeploymentFrequencyRouteEntry.tsx';
import { softwareReportsRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsRouteEntry.tsx';
import { softwareReportsVelocityRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsVelocityRouteEntry.tsx';
import { softwareRoadmapRedirectRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareRoadmapRedirectRouteEntry.tsx';
import { opsgenieSchedule1RouteEntry } from '@atlassian/jira-router-routes-opsgenie-schedule-entries/src/opsgenieSchedule1RouteEntry.tsx';
import { opsgenieScheduleRouteEntry } from '@atlassian/jira-router-routes-opsgenie-schedule-entries/src/opsgenieScheduleRouteEntry.tsx';
import { peopleProfilesRouteEntry } from '@atlassian/jira-router-routes-people-profiles-entries/src/index.tsx';
import { generalPersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/generalPersonalSettingsRouteEntry.tsx';
import { notificationsPersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/notificationsPersonalSettingsRouteEntry.tsx';
import { opsNotificationsPersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/opsNotificationsPersonalSettingsRouteEntry.tsx';
import { personalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/personalSettingsRouteEntry.tsx';
import { polarisCollectionsRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisCollectionsRouteEntry.tsx';
import { polarisIdeasRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisIdeasRouteEntry.tsx';
import { polarisOnboardingRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisOnboardingRouteEntry.tsx';
import { polarisSettingsAccessRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisSettingsAccessRouteEntry.tsx';
import { polarisSettingsGlobalFieldsRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisSettingsGlobalFieldsRouteEntry.tsx';
import { polarisUnlicensedRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisUnlicensedRouteEntry.tsx';
import { productsJsmChatopsAndVideosToolsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-chatops-and-videos-tools-entries/src/index.tsx';
import { productsJsmComplianceRouteEntry } from '@atlassian/jira-router-routes-products-jsm-compliance-entries/src/index.tsx';
import { productsJsmConfigurationRouteEntry } from '@atlassian/jira-router-routes-products-jsm-configuration-entries/src/index.tsx';
import { productsJsmEmailRequestsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-email-requests-entries/src/index.tsx';
import { productsJsmFeatureUsageRouteEntry } from '@atlassian/jira-router-routes-products-jsm-feature-usage-entries/src/index.tsx';
import { productsJsmGlobalOpsReportsRedirectRouteEntry } from '@atlassian/jira-router-routes-products-jsm-global-ops-reports-entries/src/productsJsmGlobalOpsReportsRedirectRouteEntry.tsx';
import { productsJsmGlobalOpsReportsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-global-ops-reports-entries/src/productsJsmGlobalOpsReportsRouteEntry.tsx';
import { productsJsmIncidentManagementToolsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-incident-management-tools-entries/src/productsJsmIncidentManagementToolsRouteEntry.tsx';
import { productsJsmKbPermissionsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-kb-permissions-entries/src/productsJsmKbPermissionsRouteEntry.tsx';
import { productsJsmOrganizationsDetailsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-organizations-entries/src/productsJsmOrganizationsDetailsRouteEntry.tsx';
import { productsJsmOrganizationsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-organizations-entries/src/productsJsmOrganizationsRouteEntry.tsx';
import { productsJswConfigurationRouteEntry } from '@atlassian/jira-router-routes-products-jsw-configuration-entries/src/productsJswConfigurationRouteEntry.tsx';
import { productsJswStorageManagementRouteEntry } from '@atlassian/jira-router-routes-products-jsw-configuration-entries/src/productsJswStorageManagementRouteEntry.tsx';
import { proformaExportRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/proformaExportRouteEntry.tsx';
import { proformaIssueFormsGeneralRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/proformaIssueFormsGeneralRouteEntry.tsx';
import { routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRouteEntry.tsx';
import { routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectRouteEntry.tsx';
import { routeGroupsServicedeskRouteNamesProformaIssueFormsDirectEmbedRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/routeGroupsServicedeskRouteNamesProformaIssueFormsDirectEmbedRouteEntry.tsx';
import { routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRouteEntry.tsx';
import { routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRouteEntry.tsx';
import { routeGroupsSoftwareRouteNamesProformaIssueFormsDirectRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/routeGroupsSoftwareRouteNamesProformaIssueFormsDirectRouteEntry.tsx';
import { projectComponentsRedirectRouteEntry } from '@atlassian/jira-router-routes-project-components-entries/src/projectComponentsRedirectRouteEntry.tsx';
import { projectComponentsRouteEntry } from '@atlassian/jira-router-routes-project-components-entries/src/projectComponentsRouteEntry.tsx';
import { projectNotFoundRouteEntry } from '@atlassian/jira-router-routes-project-not-found-entries/src/projectNotFoundRouteEntry.tsx';
import { projectPagesLegacyRouteEntry } from '@atlassian/jira-router-routes-project-pages-entries/src/projectPagesLegacyRouteEntry.tsx';
import { projectPagesRouteEntry } from '@atlassian/jira-router-routes-project-pages-entries/src/projectPagesRouteEntry.tsx';
import { projectSettingsComponentsRouteEntry } from '@atlassian/jira-router-routes-project-settings-components-entries/src/projectSettingsComponentsRouteEntry.tsx';
import { projectSettingsVersionsRouteEntry } from '@atlassian/jira-router-routes-project-settings-versions-entries/src/projectSettingsVersionsRouteEntry.tsx';
import { projectsRedirectRouteEntry } from '@atlassian/jira-router-routes-projects-redirect-entries/src/projectsRedirectRouteEntry.tsx';
import { projectVersionsRouteEntry } from '@atlassian/jira-router-routes-projects-versions-entries/src/projectVersionsRouteEntry.tsx';
import { rovoDemandTestsRouteEntry } from '@atlassian/jira-router-routes-rovo-demand-tests-entries/src/rovoDemandTestsRouteEntry.tsx';
import { bundlerSandboxRouteEntry } from '@atlassian/jira-router-routes-sandbox-entries/src/bundlerSandboxRouteEntry.tsx';
import { jsmBoardRouteEntry } from '@atlassian/jira-router-routes-servicedesk-board-entries/src/jsmBoardRouteEntry.tsx';
import { servicedeskCalendarRouteEntry } from '@atlassian/jira-router-routes-servicedesk-calendar-entries/src/servicedeskCalendarRouteEntry.tsx';
import { servicedeskChangeCalendarRouteEntry } from '@atlassian/jira-router-routes-servicedesk-change-calendar-entries/src/servicedeskChangeCalendarRouteEntry.tsx';
import { servicedeskCmdbAssetsGlobalConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsGlobalConfigurationRouteEntry.tsx';
import { servicedeskCmdbAssetsObjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsObjectRouteEntry.tsx';
import { servicedeskCmdbAssetsObjectSchemaConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsObjectSchemaConfigurationRouteEntry.tsx';
import { servicedeskCmdbAssetsObjectSchemaRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsObjectSchemaRouteEntry.tsx';
import { servicedeskCmdbAssetsObjectTypeConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsObjectTypeConfigurationRouteEntry.tsx';
import { servicedeskCmdbAssetsReportsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsReportsRouteEntry.tsx';
import { servicedeskCmdbAssetsReportsTemplateRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsReportsTemplateRouteEntry.tsx';
import { servicedeskCmdbAssetsReportsUnsubscribeRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsReportsUnsubscribeRouteEntry.tsx';
import { servicedeskCmdbAssetsSchemasRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsSchemasRouteEntry.tsx';
import { servicedeskCmdbInsightConfigureGlobalRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightConfigureGlobalRouteEntry.tsx';
import { servicedeskCmdbInsightConfigureRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightConfigureRouteEntry.tsx';
import { servicedeskCmdbInsightObject4RouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightObject4RouteEntry.tsx';
import { servicedeskCmdbInsightObjectSchemaConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightObjectSchemaConfigurationRouteEntry.tsx';
import { servicedeskCmdbInsightObjectSchemaRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightObjectSchemaRouteEntry.tsx';
import { servicedeskCmdbInsightObjectTypeConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightObjectTypeConfigurationRouteEntry.tsx';
import { servicedeskCmdbInsightRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightRouteEntry.tsx';
import { servicedeskCreateProjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-create-project-entries/src/servicedeskCreateProjectRouteEntry.tsx';
import { customerServiceEscalationsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-customer-service-escalations-entries/src/customerServiceEscalationsRouteEntry.tsx';
import { customerServiceProductsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-customer-service-products-entries/src/customerServiceProductsRouteEntry.tsx';
import { servicedeskCustomerDetailsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-customers-entries/src/servicedeskCustomerDetailsRouteEntry.tsx';
import { servicedeskCustomersV2RouteEntry } from '@atlassian/jira-router-routes-servicedesk-customers-entries/src/servicedeskCustomersV2RouteEntry.tsx';
import { projectServiceDeskRouteEntry } from '@atlassian/jira-router-routes-servicedesk-default-entries/src/projectServiceDeskRouteEntry.tsx';
import { jsmGSPPageRouteEntry } from '@atlassian/jira-router-routes-servicedesk-getting-started-page-entries/src/jsmGSPPageRouteEntry.tsx';
import { servicedeskItsmSampleSpaceRouteEntry } from '@atlassian/jira-router-routes-servicedesk-itsm-sample-space-entries/src/servicedeskItsmSampleSpaceRouteEntry.tsx';
import { knowledgeHubAllArticlesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubAllArticlesRouteEntry.tsx';
import { knowledgeHubArticleEmbedEditorRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubArticleEmbedEditorRouteEntry.tsx';
import { knowledgeHubArticleInCategoryRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubArticleInCategoryRouteEntry.tsx';
import { knowledgeHubBrowseAllArticlesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubBrowseAllArticlesRouteEntry.tsx';
import { knowledgeHubBrowseAllCategoriesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubBrowseAllCategoriesRouteEntry.tsx';
import { knowledgeHubBrowseViewArticleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubBrowseViewArticleRouteEntry.tsx';
import { knowledgeHubCategoryPageRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubCategoryPageRouteEntry.tsx';
import { knowledgeHubCategoryRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubCategoryRouteEntry.tsx';
import { knowledgeHubDraftsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubDraftsRouteEntry.tsx';
import { knowledgeHubLandingRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubLandingRouteEntry.tsx';
import { knowledgeHubSearchRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubSearchRouteEntry.tsx';
import { knowledgeHubSetupRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubSetupRouteEntry.tsx';
import { knowledgeHubSuggestedArticlesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubSuggestedArticlesRouteEntry.tsx';
import { notificationLogsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-notification-logs-entries/src/notificationLogsRouteEntry.tsx';
import { servicedeskOncallScheduleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-oncall-entries/src/servicedeskOncallScheduleRouteEntry.tsx';
import { servicedeskOrganizationV2RouteEntry } from '@atlassian/jira-router-routes-servicedesk-organization-entries/src/servicedeskOrganizationV2RouteEntry.tsx';
import { servicedeskOrganizationDetailsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-organizations-entries/src/servicedeskOrganizationDetailsRouteEntry.tsx';
import { servicedeskOrganizationsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-organizations-entries/src/servicedeskOrganizationsRouteEntry.tsx';
import { servicedeskProjectSummaryRouteEntry } from '@atlassian/jira-router-routes-servicedesk-project-summary-entries/src/servicedeskProjectSummaryRouteEntry.tsx';
import { customPracticeQueueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customPracticeQueueRouteEntry.tsx';
import { customQueueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customQueueRouteEntry.tsx';
import { servicedeskIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskIssueRouteEntry.tsx';
import { servicedeskPracticeEditRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeEditRouteEntry.tsx';
import { servicedeskPracticeIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeIssueRouteEntry.tsx';
import { servicedeskPracticeLandingRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeLandingRouteEntry.tsx';
import { servicedeskPracticeManageViewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeManageViewRouteEntry.tsx';
import { servicedeskPracticeNewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeNewRouteEntry.tsx';
import { servicedeskQueuesAddonRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesAddonRouteEntry.tsx';
import { servicedeskQueuesEditRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesEditRouteEntry.tsx';
import { servicedeskQueuesIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesIssueRouteEntry.tsx';
import { servicedeskQueuesLandingRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesLandingRouteEntry.tsx';
import { servicedeskQueuesManageViewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesManageViewRouteEntry.tsx';
import { servicedeskQueuesNewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesNewRouteEntry.tsx';
import { servicedeskAtlassianAnalyticsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskAtlassianAnalyticsRouteEntry.tsx';
import { servicedeskKbRequestsReports2RouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskKbRequestsReports2RouteEntry.tsx';
import { servicedeskKbRequestsReportsIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskKbRequestsReportsIssueRouteEntry.tsx';
import { servicedeskKbRequestsReportsTimeScaleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskKbRequestsReportsTimeScaleRouteEntry.tsx';
import { servicedeskReports1RouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReports1RouteEntry.tsx';
import { servicedeskReportsCustom4RouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustom4RouteEntry.tsx';
import { servicedeskReportsCustomCreateRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustomCreateRouteEntry.tsx';
import { servicedeskReportsCustomEditRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustomEditRouteEntry.tsx';
import { servicedeskReportsCustomIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustomIssueRouteEntry.tsx';
import { servicedeskReportsCustomTimeScaleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustomTimeScaleRouteEntry.tsx';
import { servicedeskReportsSatisfaction2RouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsSatisfaction2RouteEntry.tsx';
import { servicedeskReportsSatisfactionIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsSatisfactionIssueRouteEntry.tsx';
import { servicedeskReportsSatisfactionTimeScaleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsSatisfactionTimeScaleRouteEntry.tsx';
import { servicedeskReportsSummaryRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsSummaryRouteEntry.tsx';
import { servicedeskReportsTypeRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsTypeRouteEntry.tsx';
import { servicedeskReportsWorkloadRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsWorkloadRouteEntry.tsx';
import { servicedeskServiceDetailsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/servicedeskServiceDetailsRouteEntry.tsx';
import { serviceDeskServiceDetailsWithoutProjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/serviceDeskServiceDetailsWithoutProjectRouteEntry.tsx';
import { servicedeskServiceModalEditRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/servicedeskServiceModalEditRouteEntry.tsx';
import { servicedeskServiceModalNewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/servicedeskServiceModalNewRouteEntry.tsx';
import { servicedeskServicesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/servicedeskServicesRouteEntry.tsx';
import { serviceDeskServicesWithoutProjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/serviceDeskServicesWithoutProjectRouteEntry.tsx';
import { settingsAutomationRouteEntry } from '@atlassian/jira-router-routes-settings-automation-entries/src/settingsAutomationRouteEntry.tsx';
import { settingsCmdbFieldConfigRouteEntry } from '@atlassian/jira-router-routes-settings-cmdb-field-config-entries/src/settingsCmdbFieldConfigRouteEntry.tsx';
import { settingsOauthCredentialsRouteEntry } from '@atlassian/jira-router-routes-settings-oauth-credentials-entries/src/settingsOauthCredentialsRouteEntry.tsx';
import { softwareBacklogEmbedRouteEntry } from '@atlassian/jira-router-routes-software-backlog-entries/src/softwareBacklogEmbedRouteEntry.tsx';
import { softwareBacklogRouteEntry } from '@atlassian/jira-router-routes-software-backlog-entries/src/softwareBacklogRouteEntry.tsx';
import { softwareBoardEmbedRouteEntry } from '@atlassian/jira-router-routes-software-board-entries/src/softwareBoardEmbedRouteEntry.tsx';
import { softwareBoardRouteEntry } from '@atlassian/jira-router-routes-software-board-entries/src/softwareBoardRouteEntry.tsx';
import { softwareCalendarEmbedRouteEntry } from '@atlassian/jira-router-routes-software-calendar-entries/src/softwareCalendarEmbedRouteEntry.tsx';
import { softwareCalendarRouteEntry } from '@atlassian/jira-router-routes-software-calendar-entries/src/softwareCalendarRouteEntry.tsx';
import { softwareDeploymentsNextGenRouteEntry } from '@atlassian/jira-router-routes-software-deployments-entries/src/softwareDeploymentsNextGenRouteEntry.tsx';
import { softwareFormBuilderRouteEntry } from '@atlassian/jira-router-routes-software-form-builder-entries/src/softwareFormBuilderRouteEntry.tsx';
import { softwareFormRouteEntry } from '@atlassian/jira-router-routes-software-form-entries/src/softwareFormRouteEntry.tsx';
import { softwareFormSubmitEmbedRouteEntry } from '@atlassian/jira-router-routes-software-form-submit-embed-entries/src/softwareFormSubmitEmbedRouteEntry.tsx';
import { softwareFormSubmitRouteEntry } from '@atlassian/jira-router-routes-software-form-submit-entries/src/softwareFormSubmitRouteEntry.tsx';
import { softwareGoalsRouteEntry } from '@atlassian/jira-router-routes-software-goals-entries/src/softwareGoalsRouteEntry.tsx';
import { softwareListEmbedRouteEntry } from '@atlassian/jira-router-routes-software-list-entries/src/softwareListEmbedRouteEntry.tsx';
import { softwareListRouteEntry } from '@atlassian/jira-router-routes-software-list-entries/src/softwareListRouteEntry.tsx';
import { softwareOnboardingGetStartedRouteEntry } from '@atlassian/jira-router-routes-software-onboarding-entries/src/softwareOnboardingGetStartedRouteEntry.tsx';
import { softwareOnboardingRouteEntry } from '@atlassian/jira-router-routes-software-onboarding-entries/src/softwareOnboardingRouteEntry.tsx';
import { softwarePeriscopeNextGenRouteEntry } from '@atlassian/jira-router-routes-software-periscope-entries/src/softwarePeriscopeNextGenRouteEntry.tsx';
import { softwareRoadmapRouteEntry } from '@atlassian/jira-router-routes-software-roadmap-entries/src/softwareRoadmapRouteEntry.tsx';
import { softwareSecurityNextGenRouteEntry } from '@atlassian/jira-router-routes-software-security-entries/src/softwareSecurityNextGenRouteEntry.tsx';
import { softwareSummaryRouteEntry } from '@atlassian/jira-router-routes-software-summary-entries/src/softwareSummaryRouteEntry.tsx';
import { softwareTimelineRouteEntry } from '@atlassian/jira-router-routes-software-timeline-entries/src/softwareTimelineRouteEntry.tsx';
import { softwareVersionDetailEmbedRouteEntry } from '@atlassian/jira-router-routes-software-versions-entries/src/softwareVersionDetailEmbedRouteEntry.tsx';
import { softwareVersionDetailLegacyRedirectRouteEntry } from '@atlassian/jira-router-routes-software-versions-entries/src/softwareVersionDetailLegacyRedirectRouteEntry.tsx';
import { softwareVersionDetailNoTabPathRedirectRouteEntry } from '@atlassian/jira-router-routes-software-versions-entries/src/softwareVersionDetailNoTabPathRedirectRouteEntry.tsx';
import { softwareVersionDetailRouteEntry } from '@atlassian/jira-router-routes-software-versions-entries/src/softwareVersionDetailRouteEntry.tsx';
import { trashProjectsDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-trash-projects-directory-entries/src/trashProjectsDirectoryRedirectRouteEntry.tsx';
import { trashProjectsDirectoryRouteEntry } from '@atlassian/jira-router-routes-trash-projects-directory-entries/src/trashProjectsDirectoryRouteEntry.tsx';
import { userSegmentationRouteEntry } from '@atlassian/jira-router-routes-user-segmentation-entries/src/userSegmentationRouteEntry.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { softwareRoadmapClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareRoadmapClassicRouteEntry.tsx';
import { softwareTimelineClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareTimelineClassicRouteEntry.tsx';
import { rapidboardBacklogEmbedRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardBacklogEmbedRouteEntry.tsx';

import { classicSoftwareBoardSettingsInsightsRapidboardBacklogRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-settings-insights-entries/src/classicSoftwareBoardSettingsInsightsRapidboardBacklogRouteEntry.tsx';
import { classicSoftwareBoardSettingsInsightsRapidboardBoardRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-settings-insights-entries/src/classicSoftwareBoardSettingsInsightsRapidboardBoardRouteEntry.tsx';

import { rapidboardBacklogRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardBacklogRouteEntry.tsx';
import { softwareCalendarClassicEmbedRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareCalendarClassicEmbedRouteEntry.tsx';
import { softwareCalendarClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareCalendarClassicRouteEntry.tsx';
import { softwareListEmbedClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareListEmbedClassicRouteEntry.tsx';
import { softwareListClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareListClassicRouteEntry.tsx';
import { rapidboardReportRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardReportRouteEntry.tsx';
import { softwareReportsCycleTimeClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareReportsCycleTimeClassicRouteEntry.tsx';
import { softwareFormBuilderClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormBuilderClassicRouteEntry.tsx';
import { softwareFormSubmitClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormSubmitClassicRouteEntry.tsx';
import { softwareFormSubmitEmbedClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormSubmitEmbedClassicRouteEntry.tsx';
import { softwareFormClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormClassicRouteEntry.tsx';
import { softwareGoalsClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareGoalsClassicRouteEntry.tsx';
import { softwareSummaryClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareSummaryClassicRouteEntry.tsx';
import { softwareClassicProformaIssueFormsDirectRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareClassicProformaIssueFormsDirectRouteEntry.tsx';
import { softwareClassicProformaIssueFormsDirectEmbedRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareClassicProformaIssueFormsDirectEmbedRouteEntry.tsx';
import { softwareReportsDeploymentFrequencyClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareReportsDeploymentFrequencyClassicRouteEntry.tsx';
import { classicSoftwareBoardRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-entries/src/classicSoftwareBoardRouteEntry.tsx';
import { classicSoftwareBoardEmbedRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-entries/src/classicSoftwareBoardEmbedRouteEntry.tsx';
import { rapidboardUserBacklogRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardUserBacklogRouteEntry.tsx';
import { rapidboardUserReportRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardUserReportRouteEntry.tsx';
import { userBoardRouteEntry } from '@atlassian/jira-router-routes-user-board-entries/src/userBoardRouteEntry.tsx';
import { rapidboardBacklog1RouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardBacklog1RouteEntry.tsx';
import { rapidboardReport1RouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardReport1RouteEntry.tsx';
import { softwareCodeClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareCodeClassicRouteEntry.tsx';
import { softwareSecurityClassicRouteEntry } from '@atlassian/jira-router-routes-software-security-entries/src/softwareSecurityClassicRouteEntry.tsx';
import { softwareDeploymentsClassicRouteEntry } from '@atlassian/jira-router-routes-software-deployments-entries/src/softwareDeploymentsClassicRouteEntry.tsx';
import { softwarePeriscopeClassicRouteEntry } from '@atlassian/jira-router-routes-software-periscope-entries/src/softwarePeriscopeClassicRouteEntry.tsx';
import { rapidboardLastVisitedProjectRedirectRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardLastVisitedProjectRedirectRouteEntry.tsx';
import { projectDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/projectDirectoryRedirectRouteEntry.tsx';
import { softwareIncidentsClassicRouteEntry } from '@atlassian/jira-router-routes-incidents-entries/src/softwareIncidentsClassicRouteEntry.tsx';
import { legacyRapidBoardRouteEntry } from '@atlassian/jira-router-routes-software-classic-rapid-board-entries/src/legacyRapidBoardRouteEntry.tsx';
import { projectSettingsRouteEntries } from './project-settings/index.tsx';

/**
 *
 * This method should contain an array of all routes in Jira Frontend.
 *
 * Please do not remove the `export` as this function is used by BUNDLEANALYZER-ROUTES
 *
 * We aim to make these routes static, which is it currently very much not.
 * To aid the gradual refactor towards static routes, we have an eslint rule
 * (jira/js/static-routes) which enforces static content on function definitions
 * with an identifier matching regex: /^get(?!Static).+Routes$/
 *
 * We will make the plugin more strict and cover more fields as we work on this
 * refactor.
 *
 * To be a good citizen, please make your routes as static as possible and avoid
 * feature flags on routes - feature flagging inside the app rather than the route
 * definitions is preferred (and will be gradually required).
 *
 * If you need to remove a route, either just remove it or return a conditional component
 * by using componentWithCondition and return the 404 component with FF true, and current component with FF false.
 * Once rolled out you can remove the route entirely and the 404 component will be returned anyway.
 *
 * There is also a webpack plugin for SSR which we use to import routes while we
 * make routes static (`services/jira-ssr/scripts/babel-plugins/jira-routes.js`).
 * Breaking the contract we enforce with eslint is likely to break this functionality,
 * so please don't (for the sake of my greying hair).
 *
 * Remember to add routes with the same prefix from the most specific to the most generic.
 * For example:
 * /jira/product/share
 * /jira/product/create
 * /jira/product
 *
 * If you have any questions about this, please reach out to disturbed at #jira-frontend-perf
 */

export const getSpaRoutes = (): Route[] => [
	connectGeneralServiceDeskProjectRouteEntry,
	connectGeneralRouteEntry,
	connectGeneralInvalidRouteEntry,
	connectProjectRouteEntry,
	forgeAdminSettingsRouteEntry,
	forgeAdminRouteEntry,
	forgeProjectRouteEntry,
	forgeServicedeskQueueRouteEntry,
	forgeFieldContextualConfigurationRouteEntry,
	forgeGlobalRouteEntry,
	forgePersonalSettingsRouteEntry,
	softwareBoardEmbedRouteEntry,
	softwareBoardRouteEntry,
	softwareBacklogEmbedRouteEntry,
	softwareBacklogRouteEntry,
	softwareFormBuilderRouteEntry,
	softwareFormRouteEntry,
	softwareFormSubmitRouteEntry,
	softwareFormSubmitEmbedRouteEntry,
	softwareGoalsRouteEntry,
	softwareReportsBurnupRouteEntry,
	softwareReportsVelocityRouteEntry,
	softwareReportsCumulativeRouteEntry,
	softwareReportsBurndownRouteEntry,
	softwareReportsCycleTimeRouteEntry,
	softwareReportsDeploymentFrequencyRouteEntry,
	softwareReportsControlRouteEntry,
	softwareReportsRouteEntry,
	softwareNextgenRedirectRouteEntry,
	softwareCodeRouteEntry,
	softwareSecurityNextGenRouteEntry,
	softwareSummaryRouteEntry,
	softwareDeploymentsNextGenRouteEntry,
	softwarePeriscopeNextGenRouteEntry,
	softwareVersionDetailEmbedRouteEntry,
	softwareVersionDetailRouteEntry,
	softwareVersionDetailNoTabPathRedirectRouteEntry,
	softwareVersionDetailLegacyRedirectRouteEntry,
	softwareTimelineRouteEntry,
	softwareRoadmapRouteEntry,
	softwareCalendarEmbedRouteEntry,
	softwareCalendarRouteEntry,
	softwareListEmbedRouteEntry,
	softwareListRouteEntry,
	softwareRoadmapRedirectRouteEntry,
	softwareIncidentsNextGenRouteEntry,
	polarisCollectionsRouteEntry,
	polarisIdeasRouteEntry,
	polarisOnboardingRouteEntry,
	polarisSettingsAccessRouteEntry,
	polarisSettingsGlobalFieldsRouteEntry,
	polarisUnlicensedRouteEntry,
	softwareOnboardingRouteEntry,
	softwareOnboardingGetStartedRouteEntry,
	softwareRoadmapClassicRouteEntry,
	softwareTimelineClassicRouteEntry,
	classicSoftwareBoardSettingsInsightsRapidboardBacklogRouteEntry,
	classicSoftwareBoardSettingsInsightsRapidboardBoardRouteEntry,
	rapidboardBacklogEmbedRouteEntry,
	rapidboardBacklogRouteEntry,
	softwareCalendarClassicEmbedRouteEntry,
	softwareCalendarClassicRouteEntry,
	softwareListEmbedClassicRouteEntry,
	softwareListClassicRouteEntry,
	rapidboardReportRouteEntry,
	softwareReportsCycleTimeClassicRouteEntry,
	softwareFormBuilderClassicRouteEntry,
	softwareFormSubmitClassicRouteEntry,
	softwareFormSubmitEmbedClassicRouteEntry,
	softwareFormClassicRouteEntry,
	softwareGoalsClassicRouteEntry,
	softwareSummaryClassicRouteEntry,
	softwareClassicProformaIssueFormsDirectRouteEntry,
	softwareClassicProformaIssueFormsDirectEmbedRouteEntry,
	softwareReportsDeploymentFrequencyClassicRouteEntry,
	classicSoftwareBoardEmbedRouteEntry,
	classicSoftwareBoardRouteEntry,
	rapidboardUserBacklogRouteEntry,
	rapidboardUserReportRouteEntry,
	userBoardRouteEntry,
	rapidboardBacklog1RouteEntry,
	rapidboardReport1RouteEntry,
	legacyRapidBoardRouteEntry,
	softwareCodeClassicRouteEntry,
	softwareSecurityClassicRouteEntry,
	softwareDeploymentsClassicRouteEntry,
	softwarePeriscopeClassicRouteEntry,
	rapidboardLastVisitedProjectRedirectRouteEntry,
	projectDirectoryRedirectRouteEntry,
	softwareIncidentsClassicRouteEntry,
	// eslint-disable-next-line jira/route-definitions/route-array-spreads
	...projectSettingsRouteEntries,
	jiraBusinessSummaryRouteEntry,
	jiraBusinessBoardRouteEntry,
	classicBusinessProjectCalendarEmbedRouteEntry,
	classicBusinessProjectCalendarRouteEntry,
	classicBusinessProjectShortcutRouteEntry,
	jiraBusinessListRouteEntry,
	classicBusinessProjectFormRouteEntry,
	classicBusinessProjectFormSubmitRouteEntry,
	classicBusinessProjectFormBuilderRouteEntry,
	jiraBusinessTimelineRouteEntry,
	classicBusinessProjectApprovalsRouteEntry,
	classicBusinessProjectPagesRouteEntry,
	classicBusinessProjectAttachmentsRouteEntry,
	jiraBusinessTimelineEmbedRouteEntry,
	jiraBusinessListEmbedRouteEntry,
	jiraBusinessBoardEmbedRouteEntry,
	classicBusinessProjectFormSubmitEmbedRouteEntry,
	jiraBusinessSummaryEmbedRouteEntry,
	classicBusinessProjectSampleOnboardingRouteEntry,
	classicBusinessOverviewTimelineRouteEntry,
	classicBusinessOverviewCalendarRouteEntry,
	classicBusinessOverviewSummaryRouteEntry,
	jiraBusinessProjectRedirectRouteEntry,
	jiraBusinessOverviewRedirectRouteEntry,
	globalSettingsIssueFieldScreensRouteEntry,
	globalSettingsIssueFieldSchemesRouteEntry,
	globalSettingsIssueTypesScreenSchemesRouteEntry,
	globalSettingsIssueWorkflowEditRouteEntry,
	globalSettingsIssueWorkflow1RouteEntry,
	globalSettingsIssueHierarchyRouteEntry,
	globalSettingsIssueHierarchyRedirectRouteEntry,
	globalSettingsIssueTypesRouteEntry,
	globalSettingsIssueTypesSchemesRouteEntry,
	globalSettingsContactAdministratorRouteEntry,
	globalSettingsSystemUiAnnouncementBannerRouteEntry,
	productsJsmCustomerAccessRouteEntry,
	productsJsmAuthenticationSettingsRouteEntry,
	globalSettingsSystemBulkMailRouteEntry,
	globalSettingsEditPermissionSchemesRouteEntry,
	globalSettingsPermissionSchemesRouteEntry,
	globalSettingsFormDataConnectionsRouteEntry,
	globalSettingsIssuesNewIssueSearchRouteEntry,
	globalSettingsPerformanceOverviewRouteEntry,
	globalSettingsPerformanceMetricDetailRouteEntry,
	globalSettingsPerformanceProjectCleanupRouteEntry,
	globalSettingsPerformanceCustomFieldRouteEntry,
	globalSettingsPerformanceIssueArchivalRouteEntry,
	globalSettingsPerformanceProjectRoleActorRouteEntry,
	globalSettingsIssuesPrioritiesRouteEntry,
	globalSettingsIssuesPrioritySchemesRouteEntry,
	globalSettingsGlobalPermissionsRouteEntry,
	systemConfigurationRouteEntry,
	routeNamesSystemConfigurationRedirectRouteEntry,
	externalImportRouteEntry,
	emailNotificationsLogAccessRouteEntry,
	archivedIssuesRedirectRouteEntry,
	archivedIssuesJswRouteEntry,
	archivedIssuesJswCompanyRouteEntry,
	archivedIssuesJsmRouteEntry,
	archivedIssuesJwmRouteEntry,
	issueCreateEmbedRouteEntry,
	issueNavigatorProjectClassicRouteEntry,
	issueNavigatorProjectSoftwareRouteEntry,
	issueNavigatorProjectServicedeskRouteEntry,
	issueNavigatorProjectBusinessRouteEntry,
	issueNavigatorGlobalRouteEntry,
	issueNavigatorGlobalJiraRouteEntry,
	projectIssueNavigatorRouteEntry,
	globalIssueNavigatorBrowseIssueRedirectRouteEntry,
	appRequestsRouteEntry,
	promotionsRouteEntry,
	findAppsRouteEntry,
	proformaExportRouteEntry,
	proformaIssueFormsGeneralRouteEntry,
	routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRouteEntry,
	routeGroupsServicedeskRouteNamesProformaIssueFormsDirectEmbedRouteEntry,
	routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectRouteEntry,
	routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRouteEntry,
	routeGroupsSoftwareRouteNamesProformaIssueFormsDirectRouteEntry,
	routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRouteEntry,
	browseIssueEmbedRouteEntry,
	browseIssueRouteEntry,
	compassProductSettingsRouteEntry,
	confluenceProductSettingsRouteEntry,
	jiraHomeRouteEntry,
	jiraSearchRouteEntry,
	homeRedirectRouteEntry,
	homeOnboardingRedirectRouteEntry,
	devopsConnectedProjectRouteEntry,
	peopleProfilesRouteEntry,
	trashProjectsDirectoryRedirectRouteEntry,
	settingsAutomationRouteEntry,
	trashProjectsDirectoryRouteEntry,
	archivedProjectsDirectoryRouteEntry,
	projectsDirectoryRedirectRouteEntry,
	projectsDirectoryLegacyPathRedirectRouteEntry,
	projectsDirectoryRouteEntry,
	projectsDirectoryManageRedirectRouteEntry,
	manageProjectsDirectoryRouteEntry,
	projectsDirectoryManageRootRedirectRouteEntry,
	classicProjectSettingsIssueLayoutRouteEntry,
	classicProjectSettingsDataClassificationsRouteEntry,
	projectComponentsRedirectRouteEntry,
	projectComponentsRouteEntry,
	projectSettingsComponentsRouteEntry,
	projectVersionsRouteEntry,
	createProjectRouteEntry,
	projectSettingsVersionsRouteEntry,
	projectPagesLegacyRouteEntry,
	projectPagesRouteEntry,
	rovoDemandTestsRouteEntry,
	opsgenieScheduleRouteEntry,
	opsgenieSchedule1RouteEntry,
	dashboardEditRouteEntry,
	dashboardWallboardRouteEntry,
	dashboardViewRouteEntry,
	dashboardViewEmbedRouteEntry,
	dashboardsDirectoryRedirectRouteEntry,
	dashboardsDirectoryRouteEntry,
	boardsDirectoryRedirectRouteEntry,
	boardsDirectoryRouteEntry,
	filtersDirectoryRedirectRouteEntry,
	filtersDirectoryRouteEntry,
	secureFiltersDirectoryRedirectRouteEntry,
	personalSettingsRouteEntry,
	generalPersonalSettingsRouteEntry,
	notificationsPersonalSettingsRouteEntry,
	opsNotificationsPersonalSettingsRouteEntry,
	customerServiceEscalationsRouteEntry,
	customerServiceProductsRouteEntry,
	archivedPlansRouteEntry,
	trashPlansRouteEntry,
	trashedPlanDirectLinkRouteEntry,
	arjSettingsFinancialYearPageRouteEntry,
	planIncrementRouteEntry,
	planIncrementsRouteEntry,
	globalSettingsCustomFieldsRouteEntry,
	globalSettingsIssuesExplicitFieldAssociationRouteEntry,
	settingsCmdbFieldConfigRouteEntry,
	settingsOauthCredentialsRouteEntry,
	projectsRedirectRouteEntry,
	browseProjectRedirectRouteEntry,
	productsJswConfigurationRouteEntry,
	productsJswStorageManagementRouteEntry,
	productsJsmOrganizationsRouteEntry,
	productsJsmOrganizationsDetailsRouteEntry,
	productsJsmConfigurationRouteEntry,
	productsJsmComplianceRouteEntry,
	productsJsmKbPermissionsRouteEntry,
	productsJsmEmailRequestsRouteEntry,
	eocTeamCustomerLogsRouteEntry,
	eocTeamCustomerLogsDownloadRouteEntry,
	eocApiKeyManagementRouteEntry,
	globalMailSettingsRouteEntry,
	productsJsmIncidentManagementToolsRouteEntry,
	productsJsmFeatureUsageRouteEntry,
	knowledgeHubLandingRouteEntry,
	knowledgeHubBrowseAllArticlesRouteEntry,
	knowledgeHubDraftsRouteEntry,
	knowledgeHubBrowseAllCategoriesRouteEntry,
	knowledgeHubSuggestedArticlesRouteEntry,
	knowledgeHubBrowseViewArticleRouteEntry,
	knowledgeHubArticleEmbedEditorRouteEntry,
	knowledgeHubAllArticlesRouteEntry,
	knowledgeHubArticleInCategoryRouteEntry,
	knowledgeHubCategoryPageRouteEntry,
	knowledgeHubCategoryRouteEntry,
	knowledgeHubSearchRouteEntry,
	knowledgeHubSetupRouteEntry,
	servicedeskReportsSummaryRouteEntry,
	servicedeskReports1RouteEntry,
	servicedeskAtlassianAnalyticsRouteEntry,
	servicedeskReportsWorkloadRouteEntry,
	servicedeskReportsSatisfactionIssueRouteEntry,
	servicedeskReportsSatisfactionTimeScaleRouteEntry,
	servicedeskReportsSatisfaction2RouteEntry,
	servicedeskKbRequestsReportsIssueRouteEntry,
	servicedeskKbRequestsReportsTimeScaleRouteEntry,
	servicedeskKbRequestsReports2RouteEntry,
	servicedeskReportsCustomEditRouteEntry,
	servicedeskReportsCustomCreateRouteEntry,
	servicedeskReportsCustomIssueRouteEntry,
	servicedeskReportsCustomTimeScaleRouteEntry,
	servicedeskReportsCustom4RouteEntry,
	servicedeskReportsTypeRouteEntry,
	servicedeskIssueRouteEntry,
	servicedeskQueuesLandingRouteEntry,
	servicedeskQueuesNewRouteEntry,
	servicedeskQueuesEditRouteEntry,
	customQueueRouteEntry,
	servicedeskQueuesIssueRouteEntry,
	servicedeskQueuesManageViewRouteEntry,
	servicedeskQueuesAddonRouteEntry,
	servicedeskPracticeLandingRouteEntry,
	servicedeskPracticeNewRouteEntry,
	servicedeskPracticeManageViewRouteEntry,
	servicedeskPracticeEditRouteEntry,
	customPracticeQueueRouteEntry,
	servicedeskPracticeIssueRouteEntry,
	jsmBoardRouteEntry,
	jsmGSPPageRouteEntry,
	servicedeskServicesRouteEntry,
	servicedeskServiceDetailsRouteEntry,
	serviceDeskServicesWithoutProjectRouteEntry,
	serviceDeskServiceDetailsWithoutProjectRouteEntry,
	servicedeskServiceModalNewRouteEntry,
	servicedeskServiceModalEditRouteEntry,
	servicedeskCustomerDetailsRouteEntry,
	servicedeskCustomersV2RouteEntry,
	servicedeskOrganizationDetailsRouteEntry,
	servicedeskOrganizationsRouteEntry,
	servicedeskOrganizationV2RouteEntry,
	servicedeskOncallScheduleRouteEntry,
	servicedeskChangeCalendarRouteEntry,
	servicedeskCalendarRouteEntry,
	servicedeskProjectSummaryRouteEntry,
	servicedeskCmdbAssetsSchemasRouteEntry,
	servicedeskCmdbInsightRouteEntry,
	servicedeskCmdbAssetsReportsRouteEntry,
	servicedeskCmdbAssetsReportsTemplateRouteEntry,
	servicedeskCmdbAssetsReportsUnsubscribeRouteEntry,
	servicedeskCmdbAssetsObjectSchemaRouteEntry,
	servicedeskCmdbInsightObjectSchemaRouteEntry,
	servicedeskCmdbAssetsObjectTypeConfigurationRouteEntry,
	servicedeskCmdbInsightObjectTypeConfigurationRouteEntry,
	servicedeskCmdbAssetsGlobalConfigurationRouteEntry,
	servicedeskCmdbInsightConfigureGlobalRouteEntry,
	servicedeskCmdbAssetsObjectSchemaConfigurationRouteEntry,
	servicedeskCmdbInsightObjectSchemaConfigurationRouteEntry,
	servicedeskCmdbInsightConfigureRouteEntry,
	servicedeskCmdbAssetsObjectRouteEntry,
	servicedeskCmdbInsightObject4RouteEntry,
	notificationLogsRouteEntry,
	servicedeskConnectProjectRouteEntry,
	projectServiceDeskRouteEntry,
	servicedeskItsmSampleSpaceRouteEntry,
	servicedeskCreateProjectRouteEntry,
	archivedProjectDirectLinkRouteEntry,
	selectedDashboardRouteEntry,
	dashboardRouteEntry,
	systemDashboardEditRouteEntry,
	arjSettingsDependenciesRouteEntry,
	arjSettingsDependenciesLegacyRedirectRouteEntry,
	arjSettingsLoadingLimitsRouteEntry,
	arjSettingsLoadingLimitsLegacyRedirectRouteEntry,
	arjSettingsPermissionsRouteEntry,
	arjSettingsPermissionsRouteLegacyRedirectRouteEntry,
	arjCalendarEmbedRouteEntry,
	arjDependenciesEmbedRouteEntry,
	arjIncrementEmbedRouteEntry,
	arjSummaryEmbedRouteEntry,
	arjTimelineEmbedRouteEntry,
	softwarePlanDependenciesRouteEntry,
	softwarePlanSummaryRouteEntry,
	softwarePlanCalendarRouteEntry,
	softwarePlanReleasesRouteEntry,
	softwarePlanTimelineRouteEntry,
	softwarePlanSettingsPermissionsRouteEntry,
	softwarePlanSettingsSavedViewsRouteEntry,
	softwarePlanSettingsPlanInfoRouteEntry,
	softwarePlanSettingsProgramBoardsRouteEntry,
	softwarePlanSettingsSchedulingRouteEntry,
	softwarePlanSettingsScenariosRouteEntry,
	softwarePlanSettingsAutoSchedulerRouteEntry,
	softwarePlanSettingsCustomFieldsRouteEntry,
	softwarePlanSettingsExclusionRulesRouteEntry,
	softwarePlanSettingsIssueSourcesRouteEntry,
	softwarePlanSettingsFindYourIssueRouteEntry,
	softwarePlanSettingsRemovedIssuesRouteEntry,
	softwarePlanTeamsRouteEntry,
	softwareAdvancedRoadmapsGettingStartedRedirectRouteEntry,
	planSetupPageRouteEntry,
	planExportPageRouteEntry,
	planPageRouteEntry,
	planPageWithoutScenarioIdRouteEntry,
	legacyPlanPageRedirectRouteEntry,
	planReportPageRouteEntry,
	legacyPlanReportPageRedirectRouteEntry,
	plansDirectoryRouteEntry,
	eocScheduleGlobalRouteEntry,
	eocGlobalAlertListRouteEntry,
	eocGlobalAlertsDetailRouteEntry,
	eocTeamAlertListRouteEntry,
	eocTeamAlertDetailRouteEntry,
	eocGlobalGroupDetailRouteEntry,
	eocTeamGroupDetailRouteEntry,
	roleBasedNotificationsRouteEntry,
	roleBasedNotificationDetailRouteEntry,
	globalIncomingCallRouteEntry,
	globalIncomingCallDetailRouteEntry,
	unlockOperationsRouteEntry,
	teamIntegrationsRouteEntry,
	teamIntegrationListRouteEntry,
	teamIntegrationCreateRouteEntry,
	teamIntegrationDetailRouteEntry,
	teamIntegrationLogRouteEntry,
	globalIntegrationsRouteEntry,
	globalIntegrationListRouteEntry,
	globalIntegrationCreateRouteEntry,
	globalIntegrationDetailRouteEntry,
	globalIntegrationLogRouteEntry,
	alertPoliciesRouteEntry,
	globalPoliciesRouteEntry,
	globalEscalationsRouteEntry,
	eocOverviewRouteEntry,
	eocOverviewAllTeamsRouteEntry,
	eocHeartbeatsRouteEntry,
	eocMaintenanceRouteEntry,
	eocGlobalMaintenanceRouteEntry,
	eocOnCallRouteEntry,
	eocOnCall1RouteEntry,
	whoIsOnCallRouteEntry,
	eocPermissionsRouteEntry,
	eocAutomationsRouteEntry,
	eocTeamAlertIssueSyncingRouteEntry,
	eocTeamAlertIssueSyncingCreateRouteEntry,
	eocTeamAlertIssueSyncingDetailRouteEntry,
	eocAlertIssueSyncingRouteEntry,
	eocAlertIssueSyncingCreateRouteEntry,
	eocAlertIssueSyncingDetailRouteEntry,
	eocReportsTeamRedirectRouteEntry,
	eocReportsUnsubscribeRouteEntry,
	eocGlobalReportsHomePageNav4RouteEntry,
	eocGlobalReportsNav4RouteEntry,
	eocReportsTeamRouteEntry,
	eocCustomRolesRouteEntry,
	eocAssignCustomRolesRouteEntry,
	productsJsmGlobalOpsReportsRouteEntry,
	productsJsmGlobalOpsReportsRedirectRouteEntry,
	productsJsmChatopsAndVideosToolsRouteEntry,
	agentStudioRouteEntry,
	bundlerSandboxRouteEntry,
	projectNotFoundRouteEntry,
	errorsNotFoundRouteEntry,
	loginEmbedRouteEntry,
	helpCenterSettingsRedirectRouteEntry,
	helpCenterSettingsKbRouteEntry,
	helpCenterSettingsCustomerNotificationsRouteEntry,
	helpCenterSettingsCustomerAccessRouteEntry,
	helpCenterSettingsDetailsRouteEntry,
	helpCenterSettingsEmailRouteEntry,
	helpCenterSettingsFormsRouteEntry,
	helpCenterSettingsFormEditRouteEntry,
	helpCenterSettingsTicketTypesConfigRouteEntry,
	helpCenterSettingsTicketTypeFieldsRouteEntry,
	helpCenterSettingsServiceHubsRouteEntry,
	userSegmentationRouteEntry,
	// [WARNING]: This route should be the last route.
	// This route redirects to the monolith for all routes we cannot match.
	// It is purposefully missing a path so it always matches and therefore needs to be last.
	// [WARNING]: Please don't add a new route below this. If you want to add, please contact the package owner team.
	legacyRedirectRouteEntry,
];
