/**
 * @generated SignedSource<<c85d1b1d65bb7bcf6d8c3899f74414bf>>
 * @relayHash 4f0f0582e40c984988bb5a8e65160a37
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bdcff64f10b0df738a718cf0693b190f0905b4a659335df90f2f290b4a9f42f3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcCustomerServiceServiceHubsPageQuery } from './srcCustomerServiceServiceHubsPageQuery.graphql';

const node: PreloadableConcreteRequest<srcCustomerServiceServiceHubsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "bdcff64f10b0df738a718cf0693b190f0905b4a659335df90f2f290b4a9f42f3",
    "metadata": {},
    "name": "srcCustomerServiceServiceHubsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
