import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PERSONAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationForgePersonalSettingsPageResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncPersonalSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-personal-settings/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { generalPersonalSettingsRoute } from '@atlassian/jira-router-routes-personal-settings-routes/src/generalPersonalSettingsRoute.tsx';
import type GeneralPersonalSettingsType from '@atlassian/jira-spa-apps-personal-settings-general/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';
import { getLabsResources } from './common/getLabsResources.tsx';

const LazyGeneralPersonalSettings = lazyForPaint<typeof GeneralPersonalSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-personal-settings-general", jiraSpaEntry: "async-personal-settings-general" */ '@atlassian/jira-spa-apps-personal-settings-general'
		),
	),
);

export const GeneralPersonalSettingsComponent = () => (
	<LazyPage
		Page={LazyGeneralPersonalSettings}
		pageId="personal-settings-general"
		shouldShowSpinner
	/>
);

export const generalPersonalSettingsRouteEntry: Route = createEntry(
	generalPersonalSettingsRoute,
	() => ({
		group: ROUTE_GROUPS_PERSONAL_SETTINGS,
		component: GeneralPersonalSettingsComponent,
		layout: globalLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
			menuId: MENU_ID.PROFILE,
			sidebar: AsyncPersonalSettingsSidebar,
		},
		resources: [
			...getNavigationResources(),
			getConsolidationStateResource(),
			...getLabsResources(),
			...getNavigationForgePersonalSettingsPageResources(),
		],
		forPaint: [LazyAtlassianNavigation, LazyGeneralPersonalSettings],
	}),
);
