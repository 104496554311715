import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fixErrorWithAiNonFinal: {
		id: 'jql-debugger.trigger-button.fix-error-with-ai-non-final',
		defaultMessage: 'Fix with AI',
		description:
			'Button label for the trigger which invokes the JQL-Debugger AI feature. This feature allows users to fix JQL errors in the issue-navigator JQL editor',
	},
});
