import React, { createContext, useContext, type PropsWithChildren } from 'react';
import type { JqlDebuggerJqlBuilderContextProviderProps } from '../../common/types.tsx';

const jqlDebuggerContext = createContext<JqlDebuggerJqlBuilderContextProviderProps>({
	onSearch: () => undefined,
	query: '',
	jqlMessages: [],
});

/**
 * Provide additional context about the JQL Builder context to the JQL Debugger
 */
export const JqlDebuggerJqlBuilderContextProvider = (
	props: PropsWithChildren<JqlDebuggerJqlBuilderContextProviderProps>,
) => {
	const { jqlMessages, query, onSearch } = props;
	return (
		<jqlDebuggerContext.Provider value={{ query, jqlMessages, onSearch }}>
			{props.children}
		</jqlDebuggerContext.Provider>
	);
};

export const useJqlDebuggerAdditionalContext = () => useContext(jqlDebuggerContext);
