/**
 * @fileOverview
 * All variables that get passed in to the mainIssueAggQuery are defined here.
 * The abstraction is necessary because there are multiple call paths to mainIssueAggQuery,
 * so having a single source of truth for the variables eliminates a vector for
 * bugs and inconsistencies when they are invoked.
 *
 * This file will be removed (and therefore the need for the linting exemption) once we reduce
 * the number of discrete call paths for mainIssueAggQuery to one. That work will be done in
 * https://jira.atlassian.com/browse/JIV-19486.
 *
 * Sorry, Tom.
 *
 * <3 Daz
 */
/* eslint-disable jira/ff/inline-usage */
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getPermalinkId, COMMENTS } from '../../common/utils/permalinks.tsx';

const _unused = () => fg('jiv-19486-allow-fg-usage-in-early-scripts');

export const getMainIssueViewAggQueryFeatureFlagVariables = () => ({
	isCompassComponentsEnabled: true,
	giraAGGMigrationCommentsFlag:
		fg('jiv-14067-issue-details-agg-migration-comments') ||
		fg('issue-jiv-14530-comments-consistency-check'),

	threadedCommentsEnabled: fg('jira_threaded_comments_ga'),

	jsmSentimentCustomFieldFlag: true,
	isJPDPanelEnabled: fg('jpd_idea_panel_in_issue_view'),
	issueViewRelayPriorityFieldFlag: fg('relay-migration-issue-fields-priority'),

	// if assignee/reporter/user is true, it represents SingleSelectUserPicker being used
	issueViewRelayAssigneeFieldFlag:
		fg('relay-migration-issue-fields-assignee-fg') ||
		fg('relay-migration-issue-fields-reporter') ||
		fg('relay-migration-issue-fields-user-fg'),

	issueViewRelayNumberFieldFlag: fg('relay-migration-issue-fields-number'),
	issueViewRelaySprintFieldFlag: ff('relay-migration-issue-fields-sprint_jozpu', false),
	issueViewRelayAttachmentsFlag: fg('relay-migration-issue-view-attachments-'),

	issueViewRelaySingleLineTextFieldFlag:
		fg('relay-migration-issue-fields-single-line-text-fg') ||
		fg('relay-migration-issue-fields-single-line-text-msg'),

	issueViewRelayDateFieldFlag: fg('relay-migration-issue-fields-date-sg'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent: fg(
		'relay-migration-issue-header-and-parent',
	),

	issueViewRelayParentFieldFlag: fg('relay-migration-issue-header-and-parent'),
	issueViewRelayProjectFieldFlag: fg('relay-migration-issue-fields-project-select'),
	issueViewRelayDateTimeFieldFlag: fg('relay-migration-issue-fields-date-time-fg'),
	issueViewRelayMetadataFieldFlag: fg('relay-migration-issue-fields-metadata'),
	issueViewDesignsPanelFlag: expVal(
		'collapsed_designs_panel',
		'isCollapsedDesignsPanelEnabled',
		false,
	),

	isJPDDeliveryLinkTypeFilteringEnabled: fg('jpd_idea_panel_in_issue_view'),

	issueViewRelayUrlFieldFlag: fg('relay-migration-issue-fields-url'),

	issueViewRemoveSingleVersionFieldOptionsFlag: fg('relay-migration-issue-fields-issue-version'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider:
		fg('relay-migration-issue-fields-assignee-fg'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider:
		fg('relay-migration-issue-fields-reporter'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider: fg(
		'relay-migration-issue-fields-user-fg',
	),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider:
		fg('relay-migration-issue-header-and-parent'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider:
		fg('relay-migration-issue-fields-checkbox-select'),

	issueViewRemoveCheckboxSelectFieldOptionsFlag: fg('remove_issue_field_options_main_query'),

	issueViewRelaySummaryFieldFlag: ff('relay-migration-issue-fields-summary_eog1v', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider:
		fg('relay-migration-issue-fields-original-estimate'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider:
		fg('relay-migration-issue-fields-single-line-text-msg'),

	issueViewRelayColorFieldFlag: fg('relay-migration-issue-fields-color'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider:
		ff('relay-migration-issue-fields-time-tracking_eefh0', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider:
		fg('relay-migration-issue-fields-people-fg'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider:
		fg('relay-migration-issue-fields-approvers-fg'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider:
		fg('relay-migration-issue-header-and-parent'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider:
		fg('relay-migration-issue-header-and-parent'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider:
		fg('relay-migration-issue-header-and-parent'),

	issueViewRelayHeaders: fg('relay-migration-issue-header-and-parent'),

	issueViewRelayPeopleAndApproversFieldFlag:
		fg('relay-migration-issue-fields-people-fg') || fg('relay-migration-issue-fields-approvers-fg'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider:
		ff('relay-migration-issue-fields-components-select_prpz1', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider: fg(
		'relay-migration-issue-fields-team-field-next',
	),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrelayprovider:
		fg('relay-migration-issue-fields-multi-user-fg') ||
		ff('relay-migration-issue-fields-participants', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider:
		fg('relay-migration-issue-fields-multi-user-fg') ||
		ff('relay-migration-issue-fields-participants', false) ||
		ff('relay-migration-issue-fields-request-participants_itocm', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrequestparticipantsrelayprovider:
		ff('relay-migration-issue-fields-request-participants_itocm', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider:
		fg('relay-migration-issue-header-and-parent'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider:
		fg('relay-migration-issue-fields-resolution'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider:
		fg('relay-migration-issue-fields-multi-line-text-fg'),

	// TODO [RELAY]: add multi group list FF
	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider:
		fg('relay-migration-issue-fields-multi-group-picker-fg'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider:
		fg('relay-migration-issue-fields-group-picker-sg'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider:
		ff('relay-migration-issue-fields-status_qg82c', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider:
		fg('relay-migration-issue-fields-resolution') ||
		ff('relay-migration-issue-fields-status_qg82c', false),

	issueViewRelayTimeTrackingFieldFlag: ff(
		'relay-migration-issue-fields-time-tracking_eefh0',
		false,
	),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider:
		fg('relay-migration-issue-fields-radio'),

	issueViewRemoveRadioSelectFieldOptionsFlag: fg('relay-migration-issue-fields-radio'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider:
		fg('relay-migration-issue-header-and-parent'),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider:
		fg('issue_view_field_config_edit'),
	customFieldConfigurationFlag: fg('issue_view_field_config_edit'),

	__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider:
		fg('thor_colourful_single_select_milestone1_gate'),

	isPinFieldConfigurabilityEnabled: true,

	jcsIssueLayoutEapFlag: fg('jcs_issue_layout_eap'),

	issueViewSmartRepliesSettingsFlag: fg('smart-replies-system-setting') ?? false,
});

export const getMainIssueViewAggQueryVariables = () => ({
	...getMainIssueViewAggQueryFeatureFlagVariables(),
	...getCommentQueries(),
});

export const getCommentQueries = () => ({
	commentTargetId:
		fg('jiv-14067-issue-details-agg-migration-comments') ||
		fg('issue-jiv-14530-comments-consistency-check')
			? getPermalinkId(COMMENTS) || null
			: null,
});
