import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_CORE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { coreProjectLayout } from '@atlassian/jira-core-project-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { projectSettingsCoreAppsRoute } from '@atlassian/jira-router-routes-project-settings-core-routes/src/projectSettingsCoreAppsRoute.tsx';
import type ConnectAppsSettingsType from '@atlassian/jira-spa-apps-project-settings-connect-apps/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { getNavigationSidebarProjectSettingsResource } from './common/getNavigationSidebarProjectSettingsResource.tsx';

const LazyConnectAppsSettings = lazyForPaint<typeof ConnectAppsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-connect-apps", jiraSpaEntry: "async-project-settings-connect-apps" */ '@atlassian/jira-spa-apps-project-settings-connect-apps'
		),
	),
);

export const ConnectAppsPage = () => (
	<LazyPage
		Page={LazyConnectAppsSettings}
		pageId="project-settings-connect-apps"
		shouldShowSpinner={false}
	/>
);

export const projectSettingsCoreAppsRouteEntry = createEntry(projectSettingsCoreAppsRoute, () => ({
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	navigation: {
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		menuId: MENU_ID.PROJECTS,
		// delete when cleaning up getWillShowNav4
		sidebar: AsyncSidebarCore,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},
	layout: coreProjectLayout,
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getNavigationSidebarProjectSettingsResource(),
		...getBusinessProjectResources(),
	],
	component: ConnectAppsPage,
	forPaint: [LazyAtlassianNavigation, LazyConnectAppsSettings],
}));
