import {
	BAD_REQUEST,
	CONTENT_TOO_LARGE,
	NO_CONTENT,
} from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import killswitch from '@atlassian/jira-killswitch/src/index.tsx';
import HttpError, { type FieldValidationError, ValidationError } from './errors.tsx';
import { defaultOptions as generalOptions, getDefaultOptions } from './fetch-default-options.tsx';
import { getReroutableURL } from './get-reroutable-url.tsx';
import { applyObservabilityHeaders } from './observability-headers.tsx';
import { type RetryOptions, retryOnError } from './retries.tsx';
import { getTraceId } from './trace-id.tsx';

/**
 * Transform object with field errors from JIRA to an array of FieldValidationErrors
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformFieldErrorsFromServer = (fieldErrors: Record<any, any>): FieldValidationError[] =>
	Object.keys(fieldErrors).map((field) => ({
		field,
		error: fieldErrors[field],
	}));

/**
 * Transforms validation errors we receive from server
 */
export const transformValidationErrorFromServer = (
	json: {
		errorMessages?: string[];
		errors?: Record<string, string[]>;
		fieldErrors?: Record<string, string[]>;
	} & Record<string, unknown>,
	statusCode?: number,
	traceId?: string,
): ValidationError => {
	const message = json.errorMessages ? json.errorMessages.join('; ') : 'validation failed';
	const fieldErrorsJson = json.errors ?? json.fieldErrors;
	const fieldErrors = fieldErrorsJson ? transformFieldErrorsFromServer(fieldErrorsJson) : [];
	return new ValidationError(message, fieldErrors, statusCode, traceId, json);
};

const putOptions = {
	...generalOptions,
	method: 'PUT',
} as const;

const postOptions = {
	...generalOptions,
	method: 'POST',
} as const;

const deleteOptions = {
	...generalOptions,
	method: 'DELETE',
} as const;

const patchOptions = {
	...generalOptions,
	method: 'PATCH',
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleNetworkErrors = (err: Error): Promise<any> => {
	if (err instanceof TypeError) {
		throw new Error('Failed to fetch', { cause: err });
	}
	throw err;
};

const getOptions = (url: string, userOptions: RequestInit, method = 'GET'): RequestInit => {
	const defaultOptions = getDefaultOptions(url);

	return {
		...defaultOptions,
		method,
		...userOptions,
		headers: {
			...defaultOptions.headers,
			...userOptions.headers,
		},
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleErrors = (response: Response): Response | Promise<any> => {
	const traceId = getTraceId(response);
	// CONTENT_TOO_LARGE errorMessage is used for Hard Limits and, we don't want to override the error message
	if (BAD_REQUEST === response.status || CONTENT_TOO_LARGE === response.status) {
		return response.json().then((errorContent) => {
			throw transformValidationErrorFromServer(errorContent, response.status, traceId);
		});
	}
	if (!response.ok) {
		if (traceId !== undefined && traceId.length > 0) {
			throw new HttpError(
				response.status,
				`Error server response: ${response.status}`,
				traceId,
				response,
			);
		} else {
			throw new HttpError(
				response.status,
				`Error server response: ${response.status}`,
				undefined,
				response,
			);
		}
	}
	return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processResponseFromServer = (response: Response): Promise<any> => {
	if (response.status === NO_CONTENT) {
		return Promise.resolve(null);
	}
	return response.text().then((text) => (text ? JSON.parse(text) : null));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const applyErrorHandling = <ResponseType = any,>(
	responsePromise: Promise<Response>,
): Promise<ResponseType> =>
	responsePromise.then(handleErrors).then(processResponseFromServer).catch(handleNetworkErrors);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface RequestRetryOptions<ResponseType = any> {
	retryPredicate?: RetryOptions<ResponseType>['retryPredicate'];
	retryAttempts?: RetryOptions<ResponseType>['retryAttempts'];
	onRetry?: RetryOptions<ResponseType>['onRetry'];
}

type Options<ResponseType> = RequestInit & RequestRetryOptions<ResponseType>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performRequestWithRetry = <ResponseType = any,>(
	url: string,
	options: Options<ResponseType>,
) => {
	const { retryPredicate, retryAttempts, onRetry, ...requestOptions } = options;
	const executeRequest = () =>
		fg('add_observability_headers_to_fetch_default_options')
			? applyErrorHandling<ResponseType>(
					fetch(getReroutableURL(url), getOptions(url, requestOptions)),
				)
			: applyErrorHandling<ResponseType>(
					fetch(getReroutableURL(url), applyObservabilityHeaders(url, requestOptions)),
				);

	if (killswitch('platform_fetch_retries')) {
		return executeRequest();
	}

	return retryOnError<ResponseType>(executeRequest, {
		retryFunc: executeRequest,
		retryPredicate,
		retryAttempts,
		onRetry,
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performGetRequest = <ResponseType = any,>(
	url: string,
	options: RequestInit = {},
): Promise<ResponseType> => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		const reroutableUrl = getReroutableURL(url);
		return fetch(reroutableUrl, getOptions(reroutableUrl, options))
			.then(handleErrors)
			.then(processResponseFromServer)
			.catch(handleNetworkErrors);
	}

	return fetch(
		getReroutableURL(url),
		applyObservabilityHeaders(url, { ...generalOptions, ...options }),
	)
		.then(handleErrors)
		.then(processResponseFromServer)
		.catch(handleNetworkErrors);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performPutRequest = <ResponseType = any,>(
	url: string,
	options: RequestInit = {},
): Promise<ResponseType> => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		const reroutableUrl = getReroutableURL(url);
		return fetch(reroutableUrl, getOptions(reroutableUrl, options, 'PUT'))
			.then(handleErrors)
			.then(processResponseFromServer)
			.catch(handleNetworkErrors);
	}

	return fetch(getReroutableURL(url), applyObservabilityHeaders(url, { ...putOptions, ...options }))
		.then(handleErrors)
		.then(processResponseFromServer)
		.catch(handleNetworkErrors);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performPostRequest = <ResponseType = any,>(
	url: string,
	options: RequestInit = {},
): Promise<ResponseType> => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		const reroutableUrl = getReroutableURL(url);
		return fetch(reroutableUrl, getOptions(reroutableUrl, options, 'POST'))
			.then(handleErrors)
			.then(processResponseFromServer)
			.catch(handleNetworkErrors);
	}

	return fetch(
		getReroutableURL(url),
		applyObservabilityHeaders(url, { ...postOptions, ...options }),
	)
		.then(handleErrors)
		.then(processResponseFromServer)
		.catch(handleNetworkErrors);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performDeleteRequest = <ResponseType = any,>(
	url: string,
	options: RequestInit = {},
): Promise<ResponseType> => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		const reroutableUrl = getReroutableURL(url);
		return fetch(reroutableUrl, getOptions(reroutableUrl, options, 'DELETE'))
			.then(handleErrors)
			.then(processResponseFromServer)
			.catch(handleNetworkErrors);
	}

	return fetch(
		getReroutableURL(url),
		applyObservabilityHeaders(url, { ...deleteOptions, ...options }),
	)
		.then(handleErrors)
		.then(processResponseFromServer)
		.catch(handleNetworkErrors);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performPatchRequest = <ResponseType = any,>(
	url: string,
	options: RequestInit = {},
): Promise<ResponseType> => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		const reroutableUrl = getReroutableURL(url);
		return fetch(reroutableUrl, getOptions(reroutableUrl, options, 'PATCH'))
			.then(handleErrors)
			.then(processResponseFromServer)
			.catch(handleNetworkErrors);
	}

	return fetch(
		getReroutableURL(url),
		applyObservabilityHeaders(url, { ...patchOptions, ...options }),
	)
		.then(handleErrors)
		.then(processResponseFromServer)
		.catch(handleNetworkErrors);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performGetRequestWithRetry = <ResponseType = any,>(
	url: string,
	options: Options<ResponseType> = {},
) => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		return performRequestWithRetry<ResponseType>(url, getOptions(url, options));
	}

	return performRequestWithRetry<ResponseType>(url, { ...generalOptions, ...options });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performPutRequestWithRetry = <ResponseType = any,>(
	url: string,
	options: Options<ResponseType> = {},
) => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		return performRequestWithRetry<ResponseType>(url, getOptions(url, options, 'PUT'));
	}

	return performRequestWithRetry<ResponseType>(url, { ...putOptions, ...options });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performPostRequestWithRetry = <ResponseType = any,>(
	url: string,
	options: Options<ResponseType> = {},
) => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		return performRequestWithRetry<ResponseType>(url, getOptions(url, options, 'POST'));
	}

	return performRequestWithRetry<ResponseType>(url, { ...postOptions, ...options });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performDeleteRequestWithRetry = <ResponseType = any,>(
	url: string,
	options: Options<ResponseType> = {},
) => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		return performRequestWithRetry<ResponseType>(url, getOptions(url, options, 'DELETE'));
	}

	return performRequestWithRetry<ResponseType>(url, { ...deleteOptions, ...options });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performPatchRequestWithRetry = <ResponseType = any,>(
	url: string,
	options: Options<ResponseType> = {},
) => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		return performRequestWithRetry<ResponseType>(url, getOptions(url, options, 'PATCH'));
	}

	return performRequestWithRetry<ResponseType>(url, { ...patchOptions, ...options });
};
