import { ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED_CLASSIC_BUSINESS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import { CORE_PRODUCT, jiraIssueFormCreateEndpoint } from './common/constants.tsx';

export const routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRoute: InvariantRoute =
	{
		name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED_CLASSIC_BUSINESS,
		path: `/jira/${CORE_PRODUCT}/${jiraIssueFormCreateEndpoint}/embed`,
		exact: true,
	};
