import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_COMPONENTS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSoftwareProjectHorizontalNavResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/software-horizontal-navigation/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { routeNamesOpsgenieScheduleRedirectRoute } from '@atlassian/jira-router-routes-opsgenie-schedule-routes/src/routeNamesOpsgenieScheduleRedirectRoute.tsx';
import OpsgenieSchedule, { LazyOpsGenieSchedule, OpsgenieScheduleRedirect } from './ui/index.tsx';

export const opsgenieScheduleRouteEntry = createEntry(
	routeNamesOpsgenieScheduleRedirectRoute,
	() => ({
		group: ROUTE_GROUPS_COMPONENTS,
		component: !__SERVER__ ? OpsgenieScheduleRedirect : OpsgenieSchedule,
		layout: genericProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
		},
		resources: [
			...getBusinessProjectResources(),
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			staticSoftwareProjectHorizontalNavResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			projectContextResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyOpsGenieSchedule],
		isRedirect: true,
	}),
);
