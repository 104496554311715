import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_BUSINESS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRoute } from '@atlassian/jira-router-routes-proforma-routes/src/routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ProformaIssueFormsDirectEmbed, {
	LazyProformaIssueFormsDirectEmbed,
} from './ui/issue-forms-direct/embed/index.tsx';

export const routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRouteEntry: Route =
	createEntry(routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRoute, () => ({
		group: ROUTE_GROUPS_CLASSIC_BUSINESS,
		layout: chromelessLayout,
		resources: [projectContextResource],
		component: ProformaIssueFormsDirectEmbed,
		forPaint: [LazyProformaIssueFormsDirectEmbed],
		perfMetricKey: 'issue-forms-direct-embed',
	}));
