import {
	createStore,
	createActionsHook,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';
import type { DiffItem, OnSearchCallback } from '../../common/types.tsx';

type ModalDismissSource =
	| 'CROSS_CLOSE_BUTTON'
	| 'ACCEPT_FIX'
	| 'ON_ENTER_NL_MODE'
	| 'NETWORK_CALL_ABORT'
	| 'ON_NEW_JQL_SEARCH'
	| 'JS_ERROR';

export type State = {
	jqlQuery: string;
	userQueryError: string[];
	onSearch: NonNullable<OnSearchCallback>;
	status: 'INIT' | 'LOADING_RESULT' | 'VIEW_RESULT' | 'RESULT_ERROR';
	jqlDiffTokens: DiffItem[];
	isAiEnabled: boolean;
	syntaxCorrectionAttemptErrors: string[];
	singleInstrumentationID: string;
	onAiInteractionDismissed: (source: string) => void;
	exitNaturalLanguageMode: () => void;
};

type Actions = {
	setStatus: (status: State['status']) => Action<State>;
	resetStatus: (source: ModalDismissSource) => Action<State>;
	setJqlQuery: (status: State['jqlQuery']) => Action<State>;
	setIsAiEnabled: (isAiEnabled: boolean) => Action<State>;
	setJqlErorCorrectionData: (
		data: Pick<
			State,
			'jqlDiffTokens' | 'userQueryError' | 'onSearch' | 'syntaxCorrectionAttemptErrors'
		>,
	) => Action<State>;
	setSingleInstrumentationId: (id: string) => Action<State>;
	setOnAiInteractionDismissed: (callback: State['onAiInteractionDismissed']) => Action<State>;
	exitNaturalLanguageMode: () => Action<State>;
	setExitNaturalLanguageMode: (callback: State['exitNaturalLanguageMode']) => Action<State>;
};

const initialState: State = {
	jqlQuery: '',
	userQueryError: [],
	onSearch: () => undefined,
	status: 'INIT',
	jqlDiffTokens: [],
	isAiEnabled: false,
	syntaxCorrectionAttemptErrors: [],
	singleInstrumentationID: '',
	onAiInteractionDismissed() {
		// do nothing
	},
	exitNaturalLanguageMode() {
		// do nothing
	},
};

const Store = createStore<State, Actions>({
	initialState,
	// actions that trigger store mutation
	actions: {
		setJqlQuery:
			(jqlQuery) =>
			({ setState }) => {
				setState({ jqlQuery });
			},
		setStatus:
			(status) =>
			({ setState }) => {
				setState({ status });
			},
		resetStatus:
			(source: ModalDismissSource) =>
			({ setState, getState }) => {
				if (getState().status !== 'INIT') {
					if (source !== 'ACCEPT_FIX') {
						getState().onAiInteractionDismissed(source);
					}
					setState({
						status: 'INIT',
						onAiInteractionDismissed: initialState.onAiInteractionDismissed,
					});
				}
			},
		setJqlErorCorrectionData:
			({ userQueryError, onSearch, jqlDiffTokens, syntaxCorrectionAttemptErrors }) =>
			({ setState }) => {
				setState({
					userQueryError,
					onSearch,
					jqlDiffTokens,
					syntaxCorrectionAttemptErrors,
				});
			},
		setIsAiEnabled:
			(isAiEnabled) =>
			({ setState }) => {
				setState({ isAiEnabled });
			},
		setSingleInstrumentationId:
			(singleInstrumentationID) =>
			({ setState }) => {
				setState({ singleInstrumentationID });
			},
		setOnAiInteractionDismissed:
			(onAiInteractionDismissed) =>
			({ setState }) => {
				setState({ onAiInteractionDismissed });
			},
		exitNaturalLanguageMode:
			() =>
			({ getState }) => {
				getState().exitNaturalLanguageMode?.();
			},
		setExitNaturalLanguageMode:
			(exitNaturalLanguageMode) =>
			({ setState }) => {
				setState({ exitNaturalLanguageMode });
			},
	},
});

export const useJqlDebuggerStoreActions = createActionsHook(Store);

export const useDebuggerStatus = createHook(Store, {
	selector: (state) => state.status,
});

export const useJqlQuery = createHook(Store, {
	selector: (state) => state.jqlQuery,
});

export const useStoreData = createHook(Store);

export const useIsAiEnabled = createHook(Store, {
	selector: (state) => state.isAiEnabled,
});
