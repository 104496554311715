import { ROUTE_GROUPS_ERRORS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectNotFoundRoute } from '@atlassian/jira-router-routes-project-not-found-routes/src/projectNotFoundRoute.tsx';
import { projectNotFoundEntryPoint } from '@atlassian/jira-spa-apps-project-not-found/entrypoint.tsx';

export const projectNotFoundPageEntryPoint = createPageEntryPoint({
	main: projectNotFoundEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const projectNotFoundRouteEntry = createEntry(projectNotFoundRoute, () => ({
	group: ROUTE_GROUPS_ERRORS,
	entryPoint() {
		return projectNotFoundPageEntryPoint;
	},

	layout: globalLayout,
	resources: [...getNavigationResources()],
	pageId: 'project-not-found-page-id',
}));
