import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

export default {
	get(): boolean {
		return (
			fg('relay-migration-issue-fields-resolution') ||
			ff('relay-migration-issue-fields-status_qg82c', false)
		);
	},
};
